.event_by_main_container {
  display: flex;
  width: 100% !important;
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  background-color: rgba(32, 32, 32, 1);
  flex-direction: column;
  padding-bottom: 39px;
}
.facny_box_img_zoom {
  position: unset !important;
}
/* .fancybox__caption {
  width: 50% !important;
  text-align: center !important;
} */
.event_module_item_description .fancybox__caption {
  width: 10% I !important;
}
/* .event_captionTitle{

} */
.fancybox__caption {
  width: 50% !important;
}
.fancybox__caption {
  visibility: hidden !important;
}
.event_by_mid_container {
  display: flex;
  /* align-self: self-start; */
  flex-direction: column;
  align-items: center;
}
.aboutLeftText_events_by {
  font-family: Amelaryas;
  font-weight: 400;
  font-size: 28px;
  margin-top: 40px;
  line-height: 30px;
  letter-spacing: 0em;
  color: #ffffff;
}
.aboutLeftText_events_by span {
  color: #e4ac4c;
}
.event_by_Paragraph {
  text-align: center;
  font-family: Helvetica;
  padding: 15px 0px 0px 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin-top: 20px;
  /* text-align: center; */
  color: #ffffff;
  margin-inline: auto;
  z-index: 100;
  width: 63%;
}
.my_card_module {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  flex-wrap: wrap;
}
/* YourCardComponent.module.css */
/* .card {
  width: 100%; 
  height: 370px; 
  margin: 10px; 
  background-color: black;
  border: 1px solid rgba(56, 56, 56, 1);
  color: white;
  padding: 20px;
  text-align: start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s ease;
  position: relative;
}
.card_date{
  color: #ffffff;
}
.card_paragraph{
  color: #ffffff;
}

.card {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card:hover {
  opacity: 1;
}

.my_contant {
  transition: opacity 0.3s ease;
}

.card:hover .my_contant {
  opacity: 1;
}

.my_contant img {
  transition: opacity 0.3s ease;
}

.card:hover .my_contant img {
  opacity: 1;
}
.card .my_contant,
.card .card_date,
.card .card_paragraph,
.card a {
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card:hover .my_contant,
.card:hover .card_date,
.card:hover .card_paragraph,
.card:hover a {
  opacity: 1;
} */

.text_overlay {
  width: 100%;
  height: 404px;
  margin: 10px;
  background-color: black;
  border: 1px solid rgba(56, 56, 56, 1);
  color: white;
  padding: 20px;
  text-align: start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s ease, opacity 0.3s ease;
  position: relative;
}
.card_date {
  color: rgba(228, 172, 76, 1);
  position: absolute;
  top: 15px;
  left: 15px;
  font-family: Abril Fatface;
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
}

.card_paragraph {
  color: #ffffff;
  font-family: Helvetica;
  position: absolute;
  bottom: 80px;
  left: 15px;
  width: 82%;
  font-size: 22px !important;
  font-weight: 700;
  line-height: 32px;
}
.lorem_paragraph {
  font-family: Helvetica;
  color: #ffffff;
  position: absolute;
  left: 15px;
  width: 67%;
  font-size: 22px;
  top: 30%;
  font-weight: 700;
  line-height: 36px;
}
.lorem_paragraph1 {
  font-family: Helvetica;
  color: rgba(143, 143, 143, 1);
  position: absolute;
  left: 15px;
  font-size: 14px;
  /* width: 57%; */
  width: 84%;
  font-weight: 400;
  top: 50%;
  line-height: 28px;
}
.text_overlay {
  position: relative;
}
.my_contant {
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  transform: translate(0%, 0%);
}
.text_overlay a {
  color: rgba(228, 172, 76, 1);
  text-decoration: none;
  position: absolute;
  bottom: 40px;
  font-size: 18px;
  left: 15px;
  font-weight: 400;
  line-height: 30px;
}

.text_overlay:hover {
  opacity: 1;
}

.text_overlay:hover .my_contant,
.text_overlay:hover .card_date,
.text_overlay:hover a {
  opacity: 1;
}

.text_overlay:hover .my_contant {
  opacity: 1 !important;
}
.image_rec_pic {
  height: 404px;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1440px) {
  .lorem_paragraph {
    width: 90%;
  }
}
@media screen and (max-width: 1024px) {
  .card_date {
    font-size: 20px;
  }
  .lorem_paragraph {
    font-size: 18px;
    top: 28%;
    line-height: 31px;
    width: 100%;
    padding: 10px;
    left: 0px;
  }
  .lorem_paragraph1 {
    left: 15px;
    font-size: 14px;
    width: 91%;
    top: 48%;
    line-height: 25px;
  }
}
@media screen and (max-width: 768px) {
  .aboutLeftText_events_by {
    font-size: 14px;
    margin-top: 0px;
  }
  .child_container {
    position: absolute;
    top: 40%;
    /* gap: 10px; */
    transform: translate(0, -50%);
    padding: 10px;
  }
  .aboutLeftText_events {
    font-size: 17px;
  }
  .event_by_Paragraph {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    width: 86%;
    text-align: center;
    line-height: 25px;
    margin-top: 0;
    margin: 0px;
  }
  .text_overlay {
    margin: 0px;
    margin-bottom: 15px;
  }
  .card_date {
    font-size: 17px;
  }
  .lorem_paragraph1 {
    font-size: 14px;
  }
  .lorem_paragraph {
    font-size: 16px;
    width: 95%;
    padding: 10px;
    left: 4px;
  }
  .text_overlay a {
    font-size: 16px;
  }
  .card_paragraph {
    font-size: 22px !important;
  }
}
