@font-face {
  font-family: Helvetica;
  src: url("../../../public//fonts/Helvetica.ttf");
  font-weight: normal;
}
/* /desire effect */

/* header.module.css */
#drop_down_header:hover .fullWidthDropDown {
  visibility: visible;
}
.fullWidthDropDown {
  transition: visibility 0.1s ease, opacity 0.1s ease !important;
  /* other styles */
}

.fullWidthDropDown {
  position: absolute;
  width: 100%;
  top: 100%;
  transition: 0.3s;
  /* visibility: hidden; */
  z-index: 999;
  background-color: #232323f2;
  /* opacity: 1; */
  box-shadow: 0px 22px 12px 0px #00000042;
  padding: 40px 66px 43px;
}

.fullWidthDropDown p {
  margin: 0;
  padding: 10px;
}
/* .image_Mask{
  display: flex;
}
.image_Mask img{
  width: 100%;
  border: 2px solid white;
} */
.col-md-4 {
  position: relative;
}

.image_Mask {
  position: relative;
  width: 97%;
  cursor: pointer;
}

.image_Mask img {
  width: 90%;
  position: relative;
}

.image_Mask::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(30, 30, 30, 0), rgba(30, 30, 30, 0.8));
  z-index: 1;
}

.image_Mask p {
  position: absolute;
  font-family: Helvetica;
  font-weight: 400;
  top: 82%;
  left: 0px;
  padding-left: 36px;
  color: white;
  font-size: 22px;
  padding: 10px;
  margin: 0;
  z-index: 2;
  margin-left: 25px;
  margin-right: 4px;
  text-transform: capitalize;
  cursor: pointer;
}

.heroHeadingBtn_mask_button {
  position: absolute;
  top: 74%;
  left: 31px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  margin: 0;
  z-index: 2; 
}

.resBtn:hover {
  background-color: #ffffff;
  color: black;
}

.heroHeadingBtn_mask_button button {
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  width: 100%;
  padding: 11px 12px;
  border-radius: 12px;
}

.outerContainer {
  position: relative;
  width: 100%;
  font-family: Helvetica;
  z-index: 999;
}

/* .outerContainerNav {
  position: absolute;
  width: 100%;
  font-family: Helvetica;
  transition: transform 1.4s ease-in-out;
  z-index: 999;
} */
.outerContainerNav {
  position: fixed;
  width: 100%;
  font-family: Helvetica;
  /* transition: transform 0.5s ease-in-out; */
  transition: transform 1.4s ease-in-out;
  z-index: 9999;
}
.innerContainer {
  max-width: 100%;
  position: relative;
  background-color: #000000;
  height: 85px;
  padding-inline: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.logo {
  width: 180.1px;
  min-height: 29.61px;
}
.headerBtn {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  /* position: relative; */
}
.hamburgerBtn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8.71px 5.87px 8.79px 5.8px;
}

.hamburgerBtn img {
  width: 35px;
  height: 35px;
  color: #ffffff;
}
.resBtn {
  background-color: transparent;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 14px 17px 14px 17px;
  /* border: none; */
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.resBtn:hover {
  background-color: #ffffff;
  color: black;
}
.my_slider_page {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.my_slider_nlog_img1 {
  display: flex;
  width: 100%;
  padding: 10px;
}

.nLine {
  height: 39px;
  padding: 0px 13px 0px 0px;
}
.table_reservation {
  color: #ffffff;
  font-family: Helvetica;
  font-size: 15px;
  width: 25%;
  font-weight: 600;
}
.my_slide_form {
  display: flex;
  width: 100%;
}
/* form */
.HomeFormInput_slide {
  display: flex;
  padding: 4px 4px 4px 4px;
  margin-inline: auto;
  gap: 9px;
  width: 100% !important;
}
.HomeFormInput_slide {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 4px 4px 4px;
  margin-inline: auto;
  gap: 25px;
  width: 100% !important;
}
.HomeFormInput_slide_new {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 4px 4px 4px;
  margin-inline: auto;
  gap: 25px;
  width: 100% !important;
  margin-top: 30px;
}
.inputWrapper {
  margin-bottom: 22px;
  width: 49%;
}
.HomeFormInputField_slide {
  padding: 0px 3px 2px 7px;
  min-height: 40px;
  /* background-color: rgba(32, 32, 32, 1); */
  background-color: #262626;
  position: relative;
  width: 100% !important;
  font-size: 16px;
  border: 1px solid #ffffff;
  color: #e3e3e3;
  outline-color: transparent;
  margin-inline: auto;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #595959;
}
.HomeFormInputField_slide::placeholder {
  color: #595959;
}
.proceed_button {
  background-color: transparent;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 18px 24px 10px 24px;
  /* border: none; */
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.proceed_button:hover {
  background-color: #ffffff;
  color: black;
}
.proceed_to_next {
  /* display: flex; */
  width: 100% !important;
  text-align: center;
}
.proceed_to_next_new {
  /* display: flex; */
  width: 100% !important;
  margin-top: 40px;
  text-align: center;
}
/* YourStyles.module.css */
.HomeFormInputField_slide {
  position: relative;
  display: flex;
}
.calendarIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: gray; /* Set the color of the calendar icon */
}
.HomeFormInputField_slide input {
  display: none;
}

.HomeFormInputField_slide .placeholder {
  position: absolute;
  top: 50%;
  left: 8px; 
  transform: translateY(-50%);
  color: gray; 
  pointer-events: none;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.HomeFormInputField_slide input.selected + .placeholder {
  transform: translateY(-150%);
  color: transparent;
}
.modal-slide-up .modal-content .btn-close {
  color: white !important;
}
.rename_ {
  color: #ffffff;
}
.my_slider_nlog_img1_frame {
  width: 100%;
  padding: 5px;
  text-align: center;
  margin-top: 10px;
}
.slider_tanku {
  font-family: Amethysta;
  color: rgba(228, 172, 76, 1);
  font-size: 35px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 15px;
}
.my_slide_paragraph {
  display: flex;
  text-align: center;
}
.my_slide_paragraph span {
  color: rgba(154, 154, 154, 1);
  font-family: Helvetica;
}
.booking_details {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
}
.booking_details span {
  color: #ffffff;
  font-family: Helvetica;
}
.my_square_section {
  display: flex;
  width: 100%;
  /* flex-direction: row; */
  /* text-align: center; */
  margin-top: 10px;
  padding: 10px;
}
.my_background_section {
  background-color: #000000;
  width: 100%;
  height: fit-content;
  display: flex;
  padding: 15px;
  flex-direction: column;
}
.my_background_section1 {
  display: flex;
  flex-direction: row;
}
.my_square_logo {
  width: 25%;
  height: 70px;
  border: 1px solid rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 1);
  text-align: end;
}
.my_square_logo img {
  margin-top: 12px;
}
.sliding_paragraph span {
  color: rgba(165, 165, 165, 1);
  font-family: Helvetica;
}
.sliding_paragraph {
  text-align: justify;
  padding: 7px;
  width: 80%;
}
.contact_details {
  padding: 15px 0px 0px 1px;
}
.contact_details span {
  color: #ffffff;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 400;
}
.location_section {
  width: 100%;
  /* height: 20px; */
  margin-top: 12px;
  background-color: rgba(54, 54, 54, 1);
}
.name_section {
  padding: 5px 1px 1px 15px;
}
.name_section span {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
}
.contact_number_section {
  padding: 5px 1px 1px 15px;
}
.contact_number_section span {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
}
.close_btn_slideBar {
  text-align: end;
  width: 100%;
  position: absolute;
  right: 8px;
  top: 15px;
}
.btn_close {
  color: #fff;
  background-color: #262626;
  border: none;
}
/* mmmmmmmmm */
.activeNavLink:hover {
  color: #e4ac4c !important;
}

.navLinks a:hover {
  color: #e4ac4c !important;
  font-weight: bold;
}



/* navbar */

.navbarCloseBtn {
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 22px;
  padding: 15px;
}
.navContainer {
  padding: 0px 7vw;
  background: #000;
  text-transform: uppercase;
}
.navLinks {
  display: flex;
  height: 58px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  color: #fff;
  line-height: 9px;
  letter-spacing: 0em;
}
.navLinks a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-family: Helvetica;
  font-weight: 400;
}

.navLinks a:hover {
  color: #e4ac4c;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
}
.showNav {
  transform: translateY(
    -60%
  ); /* or any other transform value to make it visible */
}

.hideNav {
  transform: translateY(
    -200%
  ); /* or any other transform value to make it hidden */
}
/* //PopUp module */
@media screen and (max-width: 1440px) {
  .image_Mask{
    width: 100%;
}
}
/* header.module.css */
@media screen and (max-width: 768px) {
  .outerContainerNav{
    /* position: fixed; */
    width: 100%;
    font-family: Helvetica;
    transition: transform 0.5s ease-in-out;
    z-index: 9999;
}
  .resBtn {
    font-size: 18px;
    padding: 14px 20px 14px 20px;
    border-radius: 12px;
    border: 1px solid #f2f2f2;
  }
  .navLinks a {
    font-size: 15px;
  }
  .navLinks {
    gap: 27px;
    display: flex;
    flex-direction: column;
    height: 262px;
    /* height: 60px; */
    align-items: flex-start;
    padding: 25px;
  }
  .navContainer {
    padding: 0vw 1vw;
  }
  .innerContainer {
    max-width: 100%;
    position: relative;
    background-color: #000000;
    height: 70px;
    padding-inline: 6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }
  .showNav {
    transform: translateY(-60%);
    top: 200px;
  }
  
}
@media only screen and (max-width: 450px) {
  .logo {
    width: 90px;
    /* margin-top: 39px; */
  }
  .resBtn {
    padding: 5px 15px 5px 15px;
    font-size: 10px;
  }
  .headerBtn {
    gap: 20px;
    /* margin-top: 40px; */
  }
  .hamburgerBtn img {
    width: 28px;
    height: 28px;
  }
  .navbarCloseBtn {
    font-size: 17px;
    padding: 8px;
  }
}
@media screen and (max-width: 420px) {
  .navLinks span {
    text-decoration: none;
    color: #000000;
    font-size: 10px;
    font-family: Helvetica;
    font-weight: 400;
    width: 100%;
    border-bottom: 1px solid #0000002e;
    text-align: left;
    padding: 12px 17px 13px 21px;
  }
  .navLinks a {
    font-size: 11px;
    line-height: 15px;
    width: 100%;
    border-bottom: 1px solid #0000002e;
    text-align: left;
    padding: 10px 17px 10px 21px;
  }
  .navLinks {
    gap: 0px;
    display: flex;
    flex-direction: column;
    height: 246px;
    /* height: 60px; */
    align-items: flex-start;
    padding: 0px;
  }
  .navContainer {
    padding: 0vw 1vw;
  }
  .innerContainer {
    max-width: 100%;
    position: relative;
    background-color: #000000;
    height: 70px;
    padding-inline: 6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }
  .showNav {
    /* transform: translateY(-60%);
    top: 125px; */
    transform: translateY(0%);
    top: 0;
  }
  .resBtn {
    font-size: 15px;
    padding: 7px 15px 7px 14px;
  }
  
}
@media screen and (max-width: 290px) {
  .navLinks a {
    font-size: 9px;
  }
  .navLinks {
    gap: 10px;
    display: flex;
    flex-direction: column;
    height: 145px;
    /* height: 60px; */
    align-items: flex-start;
    padding: 15px;
  }
  .navContainer {
    padding: 0vw 1vw;
  }
  .innerContainer {
    max-width: 100%;
    position: relative;
    background-color: #000000;
    height: 70px;
    padding-inline: 6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }
  .showNav {
    transform: translateY(-60%);
    top: 125px;
  }
  .resBtn {
    font-size: 11px;
    padding: 7px 15px 7px 14px;
  }
  .navbarCloseBtn {
    font-size: 19px;
    padding: 7px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 600px) {
.about .outerContainer {
    width: 100%;
    position: relative;
    min-height: auto;
    margin-top: 60%;
}
  .heroText{
    font-size: 13px;
    line-height: 27px;
}
  .image_mask_paragraph{
    margin-bottom: 10px;
  }
 .heroShade{
    top: 47%;
    /* display: none; */
    height: 247px;
  }
  .fullWidthDropDown{
    padding: 23px 0px 43px;
    left: 0px;
  }
  .image_Mask img{
    width: 24%;
  }
  .image_Mask p{
    position: relative !important;
    top: 0% !important;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 13px;
    padding: 0px 6px;
    width: 177px;
  }

  .heroHeadingBtn_mask_button{
    position: relative;
    top: 0px;
    left: 0px;
    font-size: 13px;
    padding: 0px;
  }
  .heroHeadingBtn_mask_button button{
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    width: 100%;
    padding: 9px 9px;
    border-radius: 12px;
  }
  .image_Mask{
    display: flex;
    margin-bottom: 14px;
    margin-left: 0px;
    line-height: 21px;
  }
  .videocontainer .container{ 
    padding: 0px;
  }
  :where(.css-dev-only-do-not-override-p7e5j5).ant-modal {
   
    position: relative;
    top: 17px !important;
  }
}