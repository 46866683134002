.blurBackground {
  backdrop-filter: blur(18px);
}
.my_slider_page {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
}
.my_slider_nlog_img1_frame {
  width: 100%;
  padding: 5px;
  text-align: center;
  margin-top: 10px;
}
.my_slider_nlog_img1_frame {
  width: 100%;
  padding: 5px;
  text-align: center;
  margin-top: 10px;
}
.my_slider_nlog_img1_frame img {
  width: 30%;
}
.slider_tanku {
  font-family: Amethysta;
  color: rgba(228, 172, 76, 1);
  font-size: 35px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 15px;
}
.my_slide_paragraph {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}
.my_slide_paragraph span {
  color: rgba(154, 154, 154, 1);
  font-family: Helvetica;
  width: 50%;
}
.booking_details {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
}
.booking_details span {
  color: #ffffff;
  font-family: Helvetica;
  font-size: 17px;
}
.my_square_section {
  display: flex;
  width: 100%;
  /* flex-direction: row; */
  /* text-align: center; */
  margin-top: 10px;
  padding: 10px;
}
.my_background_section {
  background-color: #000000;
  width: 100%;
  height: fit-content;
  display: flex;
  padding: 29px 15px 40px 25px;
  flex-direction: column;
}
.my_background_section1 {
  display: flex;
  flex-direction: row;
}
.my_square_logo {
  width: 12%;
  height: 70px;
  border: 1px solid rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 1);
  text-align: center;
}
.contact_number_section span svg{
  margin-right: 7px;
}
.my_square_logo img {
  margin-top: 8px;
  width: 28px;
}
.sliding_paragraph span {
  color: rgba(165, 165, 165, 1);
  font-family: Helvetica;
}
.sliding_paragraph {
  text-align: justify;
  padding: 0px 17px;
  width: 80%;
}
.contact_details {
  padding: 15px 0px 0px 1px;
}
.contact_details span {
  color: #ffffff;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 400;
}
.location_section {
  width: 100%;
  /* height: 20px; */
  margin-top: 12px;
  background-color: rgba(54, 54, 54, 1);
  height: 91px;
}
.name_section {
  padding: 5px 1px 1px 15px;
}
.name_section span {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
}
.contact_number_section {
  padding: 5px 1px 1px 15px;
}
.contact_number_section span {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .my_slider_nlog_img1_frame img {
    width: 46%;
  }
  .my_slide_paragraph span {
    width: 75%;
}
.my_square_logo {
  width: 20%;
}
}
