@font-face {
  font-family: Helvetica;
  src: url("../../public//fonts/Helvetica.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Amelaryas;
  src: url("../../public//fonts//Amerlaryas.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Abril Fatface;
  src: url("../../public//fonts//AbrilFatface.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.outerContainer {
  width: 100%;
  /* height: 104vh; */
  position: relative;
  min-height: 100vh;
  background-color: #1e1e1e;
  font-family: Helvetica;
  padding: 55px 0px;
}
/* .innerContainer {
  padding: 5vw 6vw;
} */
.Heading {
  display: flex;
  gap: 2vw;
  padding-top: 50px;
  margin-bottom: 25px;
  /* flex-direction: column;
  justify-content: center; */
}
.Heading h1 {
  font-family: Amelaryas;
  font-size: 24px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #e4ac4c;
  width: 47%;
}
.Heading p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  width: 88%;
}
.container {
  display: flex;
  width: 100%;
  padding-top: 30px;
  justify-content: space-between;
}
.leftContainer {
  width: 100%;
}
.leftContainer img {
  width: 100%;
  object-fit: cover;
}
.leftContainer div {
  padding-top: 25px;
  color: #ffffff;
  text-align: start;
}
.leftContainer h2 {
  font-family: Abril Fatface;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}
.leftContainer h3 {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 0px;
}
.leftContainer p {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 15px;
  color: #8f8f8f;
  padding-right: 10px;
}
.leftContainer a {
  text-decoration: none;
  color: #e4ac4c;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}

.rightContainer {
  width: 52%;
  height: 550px;
  overflow-y: auto;
  /* background: red; */
  color: #ffffff;
}
.rightContainer {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.rightContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
  width: 0;
  color: transparent;
}
.card {
  text-align: start;
}
.card img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.card h2 {
  padding-top: 20px;
  font-family: Abril Fatface;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.card h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 0px;
  color: #ffffff;
}
.card p {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 15px;
  color: #8f8f8f;
  padding-right: 10px;
}
.card a {
  text-decoration: none;
  color: #e4ac4c;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}
.heroHeadingBtn {
  margin-inline: auto;
  padding-top: 30px;
  transform: translate(-50%, -30%);
  width: 207px;
}
.heroHeadingBtn button {
  background-color:#000;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  font-family: Helvetica;
  padding: 14px 25px 14px 25px;
  /* border: none; */
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.resBtn:hover {
  background-color: #e4ac4c;
  color: #fff;
}
.opop {
  color: #ffffff;
}
.OrganizeEvent_container {
  height: 90vh;
  overflow-x: auto;
}
.OrganizeEvent_container::-webkit-scrollbar {
  width: 0;
}
.aboutOrganizeEventContainer {
  padding-bottom: 30px;
}
@media screen and (max-width: 1440px) {
  .heroHeadingBtn {
    padding-top: 30px;
  }
}
/* @media screen and (min-width: 420px) and (max-width: 768px) { */
@media screen and (max-width: 1024px) {
  .Heading {
    gap: 5px;
  }
  .heroHeadingBtn {
    padding-top: 1px;
  }
  .OrganizeEvent_container {
    overflow-x: auto;
    width: 100%;
  }
  .Heading h1 {
    font-size: 22px;
  }
  .Heading p {
    font-size: 14px;
  }
  .heroHeadingBtn button {
    font-size: 13px;
    padding: 11px 30px 11px 30px;
  }
  .Heading {
    flex-direction: column;
    padding-top: 30px;
    margin-bottom: 20px;
    align-items: center;
  }
  .Heading p {
    font-size: 18px;
    width: 100%;
  }
  .card img {
    height: 100px;
  }
}
@media screen and (max-width: 768px) {
  .OrganizeEvent_container {
    /* height: fit-content; */
    overflow-x: hidden;
    margin-top: 14px;
  }
  .Heading {
    display: flex;
    gap: 4vw;
    flex-wrap: wrap;
    justify-content: center;
  }
  .Heading h1 {
    font-size: 15px;
  }
  .Heading p {
    width: 100%;
  }
  .outerContainer {
    min-height: 66vh !important;
  }
  .Heading p {
    font-size: 13px;
    line-height: 23px;
  }
  .Heading h1 {
    line-height: 0px;
  }
  .heroHeadingBtn {
    display: none;
  }
  .heroHeadingBtn button {
    padding: 10px 10px 10px 10px;
  }
  .card {
    width: 100%;
  }
  .leftContainer h2 {
    font-size: 13px;
    text-align: center;
    margin-top: 10px;
  }
  .leftContainer h3 {
    font-size: 13px;
    text-align: center;
    padding: 0;
  }
  .leftContainer div{
    padding: 10px !important;
    text-align: center;
  }
  .leftContainer p {
    text-align: center;
    font-size: 13px;
    padding-bottom: 0px;
    padding-right: 0px;
    color: #fff;
    margin: 0px;
  }
  .leftContainer a{
    text-align: center;
    margin-bottom: 10px;
  }
  .card img {
    height: fit-content;
    margin-top: 14px;
  }
  .card h2 {
    font-size: 13px;
  }
  .card h3 {
    font-size: 13px;
    padding: 0;
  }
  .card p {
    font-size: 13px;
    padding-bottom: 0;
  }
  .card a {
    font-size: 13px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 600px) {
 .Heading h1 ,.Heading div p{
  width: 100% !important;
    text-align: center !important;
 } 
}
@media (min-width: 1700px) and (max-width: 3000px) {
  .Heading {
    padding-top: 70px;
  }
  .aboutOrganizeEventContainer {
    padding-bottom: 65px;
  }
}