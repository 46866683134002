.aboutOrganizeEventConatiner {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: rgb(44 44 44);
  padding: 50px 0;
}
.aboutOrganizeEventContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: rgb(44 44 44);
  padding: 30px;
}
.about_cabans_heading {
  display: flex;
  width: 100%;
}
.slick-dots {
  top: 526px !important;
}
.aboutOrganizeEventConatiner_1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: rgb(32 32 32);
  padding: 35px 0;
}
.my_organize_content {
  color: white;
}

.organize_event_direction {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.Heading {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0px;
}

.Heading h1 {
  font-family: Amelaryas;
  font-size: 28px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0em;
  color: #e4ac4c;
}

.Heading p {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #ffffff;
  width: 60%;
  margin: 0 auto 25px;
  text-align: justify;
}

/* .Heading > div {
  padding: 20px;
} */
.leftContainer {
  padding-bottom: 12px;
}
.leftContainer img {
  width: 100%;
  object-fit: cover;
  height: 68.5vh;
}
.leftContainer div {
  padding-top: 25px;
  color: #ffffff;
  text-align: start;
}
.leftContainer h2 {
  font-family: Abril Fatface;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}
.leftContainer h3 {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 0px;
}
.leftContainer p {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 15px;
  color: #8f8f8f;
  padding-right: 10px;
}
.leftContainer a {
  text-decoration: none;
  color: #e4ac4c;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}
#canbana_slider img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.read_more_less {
  margin-bottom: 30px;
}
.read_more_read_less {
  border: none;
  background: #181818;
  color: #e4ac4c;
  margin-left: 7px;
}
.read_more_read_less_ {
  border: none;
  background: rgb(44 44 44);
  color: #e4ac4c;
  margin-left: 7px;
}
.read_more_read_less_ourdoor {
  border: none;
  background: rgb(32 32 32);
  color: #e4ac4c;
  margin-left: 7px;
}
/* .about_us_scroll_container {
    display: flex;
    width: 100%;
  } */

/* .about_us_scroll_container::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  color: transparent !important;
}
.card {
  text-align: start;
  margin-bottom: 10px;
}
.card img {
  width: 100%;
  height: auto;
  max-height: 138px;
  object-fit: contain;
}

.about_us_scroll_container {
  height: 500px;
  overflow-y: auto;
  color: #ffffff;
}

.about_us_scroll_container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.class{
    color: #ffffff;
}
@media (max-width: 768px) {
  .about_us_scroll_container {
    height: auto;
  }

  .card img {
    max-height: none; 
  }
} */

/* .about_us_scroll_container ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    color: transparent !important;
  }
  
  .card {
    text-align: start;
    margin-bottom: 10px;
  }
  
  .card img {
    width: 100%;
    height: auto;
    max-height: 138px;
    object-fit: contain;
  }
  
  .about_us_scroll_container {
    height: 500px;
    overflow-y: auto;
    color: #ffffff;
  }
  
  .about_us_scroll_container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .about_us_scroll_container ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    color: transparent !important;
  }
  
  .about_us_scroll_container {
    color: #ffffff;
  }
   */

/* styles.css */

/* styles.css */

/* .about_us_scroll_container { */
/* height: 500px; */
/* height: 100vh;
  overflow-y: auto;
  color: #ffffff; */
/* } */

.about_us_scroll_container::-webkit-scrollbar {
  width: 0;
}
/* 
.scrollContent {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
} */

.card {
  text-align: start;
  margin-bottom: 10px;
}

.card img {
  width: 100%;
  height: 205px;
  /* max-height: 138px; */
  object-fit: cover;
}
.left_conatiner_video {
  /* width: 278px; */
  width: 66%;
}
@media (max-width: 768px) {
  .about_us_scroll_container {
    height: auto;
    overflow-y: hidden !important;
    color: #ffffff;
  }

  .Heading h1 {
    font-size: 14px;
  }
  .Heading p {
    font-size: 13px;
    width: 100%;
    text-align: center;
    line-height: 22px;
  }
  .aboutOrganizeEventContainer {
    padding: 0px;
  }
  .left_conatiner_video {
    width: 100%;
  }
  .card img {
    width: 100% !important;
  }
  .ant-image {
    width: 100% !important;
  }
  .my_image_mask {
    width: 100%;
  }
  :where(.css-dev-only-do-not-override-p7e5j5).ant-image {
    position: relative;
    display: inline-block;
    width: 100%;
  }
}
