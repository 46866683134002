.home_crousel_container {
  background-color: #1d1d1d;
  padding: 40px 0px;
}
.notorious_paragraph span{
  color: #e4ac4c;
}
.home_crousel_heading {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.slider_container {
  display: flex;
  align-items: center;
  padding: 25px;
  background: #1b1b1b;
  gap: 40px;
  position: relative;
}

.slider_container_left {
  width: 70%;
}

.slider_container_left img {
  width: 100%;
}

.slider_container_right {
  width: 50%;
  color: white;
}

.slider_container_top {
  content: " ";
  height: 4px;
  width: 140px;
  background: #e4ac4c;
  position: absolute;
  right: 0;
  top: -5px;
  /* z-index: 1; */
}

.slider_container_rightSide {
  content: " ";
  height: 140px;
  width: 4px;
  background: #e4ac4c;
  position: absolute;
  right: -5px;
  top: -5px;
}

.slider_container_topSide {
  content: " ";
  height: 4px;
  width: 140px;
  background: #e4ac4c;
  position: absolute;
  right: -5px;
  top: -5px;
}

.slider_container_rightSide {
  content: " ";
  height: 140px;
  width: 4px;
  background: #e4ac4c;
  position: absolute;
  right: -5px;
  top: -5px;
}

.slider_container_leftSide {
  content: " ";
  height: 140px;
  width: 4px;
  background: #e4ac4c;
  position: absolute;
  left: -5px;
  bottom: -5px;
}

.slider_container_bottomSide {
  content: " ";
  height: 4px;
  width: 140px;
  background: #e4ac4c;
  position: absolute;
  left: -5px;
  bottom: -5px;
}

.home_crousel_container .carousel-indicators {
  position: absolute;
  right: 22%;
  top: 19%;
  
  left: unset;
  display: unset !important;
}

.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.carousel-control-next,
.carousel-control-prev {
  /* right: 0; */
  display: none;
}

/* .slider_container_left {
    content: " ";
    position: absolute;
} */

/* .slider_container_bottom {
    content: " ";
    position: absolute;
} */
.slider_outer {
  position: relative;
}
.notorious_head {
  color: #e4ac4c;
  font-family: Amelaryas;
  font-size: 28px;
  font-weight: 400;
  line-height: 30px;
}
.we_have {
  font-family: Amelaryas;
  font-size: 28px;
  font-weight: 400;
  line-height: 30px;
}
.notoriopus_heading_p {
  width: 84%;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
.notorious_story {
  display: flex;
  font-family: Helvetica;
  color: white;
  font-style: italic;
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  line-height: 30px;
}
.notorious_paragraph {
  font-size: 18px;
  margin-top: 18px;
  text-align: justify;
  font-family: "Helvetica";
  font-weight: 400;
  line-height: 30px;
}
.notorious_paragraph {
  /* overflow: hidden; */
  display: -webkit-box;
  -webkit-line-clamp: 9; /* Show only 3 lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

/* Add additional styles based on your design preferences */
.read_more_button {
  font-family: "Helvetica";
  font-weight: 400;
  line-height: 28px;
  color: rgba(228, 172, 76, 1);
  background-color: #000;
  border: none;
  font-size: 18px;
}

.carousel-indicators .active {
  background-color:rgba(228, 172, 76, 1) !important;
  border: 1px solid rgba(228, 172, 76, 1) !important;
}
@media screen and (max-width: 1280px) {
  .home_crousel_container .carousel-indicators {
    position: absolute;
    right: 20.5%;
    top: 20%;
    left: unset;
    display: unset !important;
  }
}
@media screen and (max-width: 1024px) {
  .notorious_paragraph {
    font-size: 15px;
    margin-top: 18px;
    text-align: justify;
    font-family: "Helvetica";
    font-weight: 400;
    line-height: 28px;
  }
  .notorious_story {
    display: flex;
    font-family: Helvetica;
    color: white;
    font-style: italic;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
  }
  .home_crousel_container .carousel-indicators {
    position: absolute;
    right: 20.5%;
    top: 15%;
    left: unset;
    display: unset !important;
  }
  .we_have {
    font-size: 26px;
  }
}
@media screen and (max-width: 991px) {
  .slider_container {
    flex-direction: column;
  }

  .slider_container_left {
    width: 100%;
  }

  .slider_container_right {
    width: 100%;
  }

  .home_crousel_container .carousel-indicators {
    top: 71%;
    right: 30%;
  }
}

@media screen and (max-width: 767px) {
  .notorious_paragraph {
    -webkit-line-clamp: 0;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 30px;
    margin-top: 18px;
    text-align: justify;
    text-overflow: ellipsis;
}
  .home_crousel_container .carousel-indicators {
    top: 62%;
    right: 30%;
  }
  .notoriopus_heading_p {
    width: 100%;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    text-align: justify;
  }
  .we_have {
    font-size: 15px;
  }
  .notorious_head {
    font-size: 15px;
  }
  .notorious_story {
    font-size: 20px;
    justify-content: center;
    margin: 0px;
  }
  .notorious_paragraph {
    font-size: 13px;
  }
}
@media screen and (max-width: 430px) {
  .read_more_button {
    background-color: #000;
    border: none;
    color: #e4ac4c;
    line-height: 28px;
    font-size: 13px;
}
  .home_crousel_container .carousel-indicators {
    top: 56%;
    right: 30%;
  }
}
@media screen and (max-width: 428px) {
  .read_more_button {
    background-color: #000;
    border: none;
    color: #e4ac4c;
    line-height: 28px;
    font-size: 13px;
}
  .home_crousel_container .carousel-indicators {
    top: 53%;
    right: 30%;
  }
}
@media screen and (max-width: 425px) {
  .read_more_button {
    background-color: #000;
    border: none;
    color: #e4ac4c;
    line-height: 28px;
    font-size: 13px;
}
  .home_crousel_container .carousel-indicators {
    top: 50%;
    right: 30%;
  }
}
@media screen and (max-width: 376px) {
  .read_more_button {
    background-color: #000;
    border: none;
    color: #e4ac4c;
    line-height: 28px;
    font-size: 13px;
}
  .home_crousel_container .carousel-indicators {
    top: 48%;
    right: 30%;
  }
}
@media screen and (max-width: 360px) {
  .home_crousel_container .carousel-indicators {
    top: 45%;
    right: 30%;
  }
  .read_more_button {
    background-color: #000;
    border: none;
    color: #e4ac4c;
    line-height: 28px;
    font-size: 13px;
}
}
@media screen and (max-width: 320px) {
  .read_more_button {
    background-color: #000;
    border: none;
    color: #e4ac4c;
    line-height: 28px;
    font-size: 13px;
}
  .notorious_paragraph {
    line-height: 20px;
  }
  .notorious_paragraph {
    font-size: 13px !important;
    margin-top: 8px !important;
    line-height: 19px !important;
  }
  .notoriopus_heading_p {
    font-family: Helvetica;
    font-size: 13px;
    line-height: 22px;
}
}
@media screen and (max-width: 280px) {
  .notoriopus_heading_p {
    line-height: 21px;
  }
  .notorious_paragraph {
    line-height: 21px;
  }
  .home_crousel_container .carousel-indicators {
    top: 37%;
    right: 30%;
  }
}
