.main_container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.event_child_container {
  position: relative;
  width: 100%;
}

.image_event_main_conatiner {
  width: 100%;
  /* height: 100%; */
  height: 470px;
  object-fit: cover;
}

.my_event_picture {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.child_container {
  position: absolute;
  text-align: -webkit-center;
  top: 40%;
  /* gap: 10px; */
  transform: translate(0, -50%);
  padding: 10px;
}

.Events_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutLeftText_events_ {
  font-family: Amelaryas;
  font-size: 33px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #ffffff;
  position: relative;
  text-align: left;
}
.aboutLeftText_events_ span {
  color: #e4ac4c;
  margin-right: 7px;
}
.event_Paragraph {
  font-family: Helvetica;
  width: 45%;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
}

.event_page_button {
  position: absolute;
  top: 88%;
  width: 44%;
  display: flex;
  padding: 13px 0px 0px 0px;
  gap: 13px;
}
.event_page_button button {
  background-color: #000000cc;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 15px 24px 15px 23px;
  /* border: none; */
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.resBtn {
  background-color: #ffffff;
  color: black;
}
.resBtn:hover {
  background-color: #ffffff;
  color: black;
  /* border: none; */
}
.notorious_event_page_button {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* width: 85%; */
  gap: 10px;
  align-items: self-start;
  margin-left: 51px;
}
@media screen and (max-width: 768px) {
  .notorious_event_page_button {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    gap: 10px;
    align-items: center;
    margin: 0px auto;
  }
  .image_event_main_conatiner {
    display: none;
    background-color: black;
}
  .event_page_button button {
    padding: 8px 10px 10px 13px;
    font-size: 14px;
  }
  .event_page_button {
    position: absolute;
    top: 81%;
    width: 100%;
    display: flex;
    /* padding: 13px 0px 0px 0px; */
    gap: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .event_Paragraph {
    font-family: Helvetica;
    width: 97%;
    color: white;
    font-size: 14px;
    text-align: center;
    margin: auto;
  }
  .aboutLeftText_events_ {
    font-size: 17px;
  }
  .main_container {
    /* height: 50vh; */
    height: 26vh;
  }
  .child_container {
    position: absolute;
    top: 40%;
    /* gap: 10px; */
    transform: translate(0, -50%);
    padding: 10px;
  }

}
@media (min-width: 1700px) and (max-width: 3000px) {
  .image_event_main_conatiner {
    height: 532px;
  }
  .child_container {
    padding: 50px;
  }
  .event_page_button {
    padding: 0;
  }
  .event_page_button {
    top: 80%;
    padding: 15px 0px;
  }
}