.myConatctUs_class {
  width: 100%;
  padding-top: 40px;
  position: relative;
  padding-bottom: 70px;
  /* height: 105vh; */
  width: 100%;
  padding-top: 40px;
  position: relative;
  padding-bottom: 15px;
  /* height: 105vh; */
  min-height: 500px;
}
.errorMessage ,.email{
  color: rgb(223, 45, 45);
    font-size: 13px;
    font-style: italic;
    /* margin-top: 21px; */
    font-family: sans-serif;
}
.contact_info2_ a,.contact_info2_location_sharp a{
  color: #fff;
  text-decoration: none;
}
.contact_us {
  background-color: #181818;
  width: 700px;
}
.contact_us_third_slide2 {
  padding: 10px 20px;
  width: 34%;
}
.contact_us_third_slide {
  width: 49%;
  padding: 10px;
}
.rectangleOutline {
  height: 100%;
  position: relative;
  /* overflow: hidden; */
}
.person {
  color: #ffffff;
}
.person1 {
  border: 5px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  height: 570px;
}
.contact_us_heading {
  background: #181818;
  width: 700px;
}
.rectangleOutline img {
  width: 100%;
  /* height: 80vh; */
  /* display: block; */
}

.connect_with_Us {
  color: #fff;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  position: absolute;
  /* top: 0; */
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.connect_with_Us span {
  color: #e4ac4c;
  font-family: Amelaryas;
}

.color {
  color: #fff;
}
.main_conatiner {
  display: flex;
  /* flex-wrap: wrap; */
  position: absolute;
  top: 18%;
  width: 100%;
  text-align: justify;
  /* left: 5%; */
  gap: 30px;
}
.main_contact_info {
  display: flex;
  align-items: center;
}

.main_contact_info span {
  font-size: 51px;
  font-family: Helvetica;
  color: #8f8f8f;
  font-weight: 700;
  white-space: nowrap;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  transform-origin: center center;
}
.my_main_contact_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact_info2 {
  color: rgba(177, 177, 177, 1);
  font-family: Helvetica;
}
.contact_info2_ {
  color: rgba(255, 255, 255, 1);
}
.contact_info2_ svg {
  color: rgba(228, 172, 76, 1);
}
.my_conatact_info_col {
  display: flex;
  flex-direction: column;
  margin: 0px;
  gap: 40px;
  /* padding: 9px 30px; */
  /* width: 100%; */
}
.contact_info2_1 {
  display: flex;
  justify-content: space-between;
}
.contact_info2_1 svg {
  color: rgba(228, 172, 76, 1);
}
.my_contact_form_main_container {
  background-color: rgba(52, 52, 52, 1);
  width: 100% !important;
  padding: 15px;
  margin-left: 10px;
}
.my_contact_form {
  font-family: Helvetica;
  font-weight: 500;
  font-size: 25px;
  color: #fff;
  display: flex;
  text-align: justify;
  line-height: 45px;
  margin-left: 8px;
}
.my_input_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin-top: 15px; */
}
.HomeFormInput_ {
  padding: 4px 10px 10px 10px;
  margin-inline: auto;
  gap: 0px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
}
.inputWrapper {
  margin-bottom: 16px;
  width: 49%;
}
.HomeFormInputField_ {
  padding: 0px 3px 2px 7px;
  min-height: 40px;
  background-color: rgba(52, 52, 52, 1);
  position: relative;
  width: 100% !important;
  font-size: 16px;
  border: 1px solid #ffffff;
  color: #e3e3e3;
  outline-color: transparent;
  margin-inline: auto;
}
.HomeFormInputField_::placeholder {
  color: rgba(151, 151, 151, 1);
}
.HomeFormInputField_::placeholder,
.HomeFormInputField_new::placeholder,
.HomeFormSelect_::placeholder,
.HomeFormTextarea_::placeholder {
  color: rgba(151, 151, 151, 1);
}
.HomeFormSelect_::placeholder {
  color: rgba(151, 151, 151, 1);
}
.HomeFormInputField_new {
  padding: 0px 3px 2px 7px;
  min-height: 40px;
  background-color: rgba(52, 52, 52, 1);
  position: relative;
  width: 100% !important;
  font-size: 16px;
  border: 1px solid #ffffff;
  color: #e3e3e3;
  outline-color: transparent;
  margin-inline: auto;
}
.HomeFormInputField_new::placeholder {
  color: rgba(151, 151, 151, 1);
}
.HomeFormSelect_ {
  padding: 0px 3px 2px 7px;
  min-height: 40px;
  background-color: rgba(52, 52, 52, 1);
  position: relative;
  width: 100% !important;
  font-size: 16px;
  margin-top: 16px;
  border: 1px solid #ffffff;
  color: rgba(151, 151, 151, 1);
  outline-color: transparent;
  margin-inline: auto;
}
.HomeFormTextarea_ {
  padding: 7px;
  min-height: 63px;
  background-color: rgba(52, 52, 52, 1);
  position: relative;
  width: 100% !important;
  font-size: 16px;
  border: 1px solid #ffffff;
  color: #e3e3e3;
  outline-color: transparent;
  margin-inline: auto;
  margin-top: 16px;
}
.HomeFormTextarea_::placeholder {
  color: rgba(151, 151, 151, 1);
}
/* .SubmitButton {
  background-color: #000000cc;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 14px 24px 14px 24px;
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
  
} */
.SubmitButton {
  font-family: Helvetica;
  padding: 7px 23px;
  background-color:#343434;
  color:  rgba(151, 151, 151, 1);
  font-size: 16px;
  border: 1px solid #ffffff;
  cursor: pointer;
  outline: none;
  margin-left: auto;
  border-radius: 10px;
  margin-top: 14px;
  cursor: pointer;
}
.SubmitButton:hover {
  color: white;
  background-color: #e4ac4c;
}
.flex_direction {
  display: flex;
  gap: 8px;
  flex-direction: row;
}
.contact_info2_location_sharp {
  width: 70%;
  color: rgba(255, 255, 255, 1);
}
.contact_info2_location_sharp svg {
  color: rgba(228, 172, 76, 1);
}
.contact_info2_1_icon {
  display: flex;
  width: 100%;
  gap: 6%;
}
.contact_info2_1_icon svg {
  color: rgba(228, 172, 76, 1);
}
@media (min-width: 1700px) and (max-width: 3000px) {
  .myConatctUs_class {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  .main_contact_info {
    margin-left: 34px;
  }
  .main_conatiner {
    gap: 0px;
  }
}

@media (max-width: 1440px) {
  .my_conatact_info_col {
    gap: 30px;
    /* width: 100%; */
  }
  .contact_info2_location_sharp {
    width: 86%;
    color: rgba(255, 255, 255, 1);
  }
  .main_contact_info span {
    font-size: 47px;
  }
  .connect_with_Us {
    font-size: 26px;
  }
  .my_contact_form_main_container {
    width: 90% !important;
    padding: 7px;
    margin-left: 2px;
  }
}
@media (max-width: 1024px) {
  .contact_us {
    width: 400px;
  }
  .contact_us_third_slide2 {
    padding: 12px 0px;
    width: 34%;
  }
  .person1 {
    border: 5px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    height: 423px;
  }
  .contact_us_heading {
    width: unset;
  }
  .rectangleOutline img {
    width: 100%;
    /* height: 65vh; */
    display: block;
    /* height: auto; */
  }
  .connect_with_Us {
    font-size: 19px;
  }
  .my_conatact_info_col {
    display: flex;
    flex-direction: column;
    margin: 0px;
    gap: 13px;
    /* width: 92%; */
    padding: 0px;
  }
  .main_contact_info span {
    font-size: 37px;
  }
  .contact_us_third_slide {
    width: 48%;
    padding: 10px;
  }
  .my_main_contact_info {
    font-size: 13px;
  }
  .main_conatiner {
    top: 21%;
    /* left: 5%; */
  }
  .my_contact_form {
    font-size: 18px;
    margin-left: 10px;
    line-height: 35px;
  }
  .inputWrapper {
    margin-bottom: 10px;
    width: 49%;
  }
  .HomeFormSelect_ {
    padding: 0px 3px 2px 7px;
    min-height: 33px;
    background-color: rgba(52, 52, 52, 1);
    position: relative;
    width: 100% !important;
    font-size: 11px;
    margin-top: 11px;
  }
  .HomeFormTextarea_ {
    padding: 7px;
    min-height: 37px;
    font-size: 13px;
    margin-top: 11px;
  }
  .HomeFormInputField_ {
    padding: 0px 3px 2px 7px;
    min-height: 33px;
    background-color: rgba(52, 52, 52, 1);
    position: relative;
    width: 100% !important;
    font-size: 12px;
    border: 1px solid #ffffff;
    color: #e3e3e3;
    outline-color: transparent;
    margin-inline: auto;
  }
  .HomeFormInputField_new {
    padding: 0px 3px 2px 7px;
    min-height: 33px;
    font-size: 12px;
    margin-top: 11px;
  }
}
@media (max-width: 768px) {
  .SubmitButton {
    margin-right: 60px !important;
  }
  .my_contact_form_main_container {
    width: 86% !important;
    padding: 7px;
    margin-left: 2px;
  }
  .contact_us {
    width: 340px;
  }
  .inputWrapper {
    margin-bottom: 0px;
    width: 100%;
  }
  .flex_direction {
    display: flex;
    gap: 11px;
    flex-direction: column;
  }
  .connect_with_Us {
    font-size: 16px;
  }
  .contact_us_third_slide2 {
    padding: 10px 20px;
    width: 100%;
  }
  .contact_us_third_slide {
    width: 100%;
    padding: 10px;
  }
  .ConnectWithUs_rectangleOutline__ img {
    width: 100%;
    /* height: 80vh; */
    display: block;
    display: none;
  }
  .main_conatiner {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 11%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    /* left: 5%; */
    /* padding: 3px 2px 3px 6px; */
  }
  .main_contact_info span {
    font-size: 29px;
    display: none;
  }
  .main_contact_info span {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
  }
  .person1 {
    height: 785px;
  }
  .my_conatact_info_col {
    display: flex;
    flex-direction: column;
    margin: 0px;
    gap: 15px;
    width: 100%;
    padding: 0px;
  }
  .person1 {
    height: 755px;
  }
}
@media (max-width: 425px) {
  .main_conatiner {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 7%;
    width: 94%;
    display: flex;
    flex-direction: column-reverse;
    /* left: 5%; */
    /* padding: 3px 2px 3px 6px; */
  }
  .person1 {
    height: 800px;
  }
  .contact_us {
    width: 260px;
  }
  .connect_with_Us {
    font-size: 13px;
    margin-top: 4px;
  }
}
@media (max-width: 280px) {
  .connect_with_Us {
    font-size: 13px;
  }
  .contact_us_third_slide {
    width: 93%;
    /* padding: 10px; */
  }
  .my_main_contact_info {
    font-size: 10px;
  }
  .SubmitButton {
    font-size: 10px;
  }
  .inputWrapper {
    margin-bottom: 10px;
    width: 100%;
  }
  .flex_direction {
    display: flex;
    gap: 0px;
    flex-direction: column;
  }
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .ContactUs_event_page_button__yQ9EQ button {
    background-color: #000000cc;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: #ffffff;
    font-family: Helvetica;
    padding: 11px 16px 11px 15px;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    min-height: 17px;
    cursor: pointer;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
  }
  .child_container {
    position: relative;
    /* top: 0%; */
    /* gap: 10px; */
    transform: translate(0, 0%);
    padding: 10px;
    background-color: #000;
    text-align: center;
    height: 238px;
  }
}
