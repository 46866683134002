#out_door_modal img ,
#canbana_slider img,
#speaksy_model img{
    width: 100%;
    height: 500px;
    object-fit: cover;
} 
#speakeasy img{
height: 400px;
object-fit: scale-down;
}
.modal-title{
    font-family: Amelaryas;
    font-size: 28px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    color: #e4ac4c;
}
#carouselExampleControls ,#out_door_controls{
    position: relative;
    transform: translate(0px, 45%);
}
#speakeasy{
    position: relative;
    transform: translate(0px, 30%);
}
#speakeasy .carousel-control-next, 
#speakeasy .carousel-control-prev{
    position: absolute;
    top: 50% !important;
    /* /* bottom: 0; */
}
@media (max-width: 768px) {
    #carouselExampleControls ,#out_door_controls{
        position: relative;
        transform: translate(0px, 0%) !important;
    }
    #speakeasy{
        position: relative;
        transform: translate(0px, 0%) !important;
    }
    #speakeasy .carousel-control-next, 
    #speakeasy .carousel-control-prev{
        position: absolute;
        top: 50% !important;
        /* /* bottom: 0; */
    }
}
@media (min-width: 1700px) and (max-width: 3000px) {
    #carouselExampleControls, #out_door_controls {
        position: relative;
        transform: translate(0px, 50%) !important;
    }
    #speakeasy {
        position: relative;
        transform: translate(0px, 42%) !important;
    }
}
