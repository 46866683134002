.my_slider_page {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.HomeFormInput_slide {
  display: flex;
  padding: 4px 4px 4px 4px;
  margin-inline: auto;
  gap: 9px;
  width: 100% !important;
}
.HomeFormInput_slide {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 4px 4px 4px;
  margin-inline: auto;
  gap: 16px;
  width: 100% !important;
}
.HomeFormInputField_slide {
  padding: 0px 3px 2px 7px;
  min-height: 40px;
  /* background-color: rgba(32, 32, 32, 1); */
  background-color: #262626;
  position: relative;
  width: 100% !important;
  font-size: 16px;
  border: 1px solid #ffffff;
  color: #e3e3e3;
  outline-color: transparent;
  margin-inline: auto;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #595959;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-panel-layout {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  background-color: black !important;
}
.HomeFormInputField_slide.active {
  border-color: #ff0000;
}
.error {
  line-height: 0px;
  color: #ff0000;
  padding-left: 1%;
}
.HomeFormInputField_slide::placeholder {
  color: #595959;
}
.datePicker input::placeholder {
  color: #595959 !important;
}
.datePicker .ant-picker-icon {
  color: #595959 !important;
}
.timePicker input::placeholder {
  color: #595959 !important;
}

.timePicker .ant-picker-icon {
  color: #595959 !important;
}
.proceed_to_next {
  /* display: flex; */
  width: 100% !important;
  text-align: center;
}
.proceed_button {
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 11px 24px 11px 24px;
  /* border: none; */
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.proceed_button:hover {
  color: white;
  background-color: #e4ac4c;
}
.modalWrapper {
  backdrop-filter: blur(18px);
}
.my_slider_nlog_img1_frame {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.my_slider_nlog_img1_frame img {
  width: 30%;
}
.reserve_seat {
  margin-top: 0px;
}
.reserve_seat span {
  color: #ffffff;
  font-family: Helvetica;
  font-size: 25px;
}
.aboutLeftText {
  font-family: Amelaryas;
  font-size: 1.9vw;
  font-weight: 400;
  /* line-height: 30px; */
  letter-spacing: 0em;
  color: #ffffff;
  padding: 15px;
}
.aboutLeftText span {
  color: #e4ac4c;
}
#registeration_form input::placeholder {
  color: rgba(151, 151, 151, 1);
}
.HomeFormInputField_slide::placeholder {
  color: rgba(151, 151, 151, 1) !important;
}
.datePicker input::placeholder {
  color: rgba(151, 151, 151, 1) !important;
}

.datePicker .ant-picker-icon {
  color: rgba(151, 151, 151, 1) !important;
}

.timePicker input::placeholder {
  color: rgba(151, 151, 151, 1) !important;
}

.timePicker .ant-picker-icon {
  color: rgba(151, 151, 151, 1) !important;
}

@media screen and (max-width: 768px) {
  .aboutLeftText span {
    font-size: 23px;
  }
  .proceed_button {
    font-size: 12px;
    padding: 8px 24px 7px 24px;
  }
}
