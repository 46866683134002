.centerContent{
    background-color: #000
}
.Heading{
    font-family: Amelaryas;
    font-size: 18px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 100%;
    margin: 0px;
   
}
.Heading span{
    color: #e4ac4c;

}
.textContent {
    font-family: Helvetica;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    padding: 20px 5vw 0px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    line-height: 23px;

}
.mobile_gallery_view{
    background-color: #000;
    padding: 20px 0px;
    display: none;
}
.gallery_slider img{
    width: 100%;
    height: 246px;
    object-fit: cover;
    margin: 12px 0px;
  }
 

.visitBtn{
    background-color: #000000cc;
    font-size: 18px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: #ffffff;
    font-family: Helvetica;
    padding: 13px 16px 13px 16px;
    /* border: none; */
    border-radius: 12px;
    border: 1px solid #f2f2f2;
    min-height: 17px;
    cursor: pointer;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
@media only screen and (min-width: 360px) and (max-width: 600px) {
    .visitBtn{
        background-color: #000000cc;
        font-size: 14px;
        padding: 10px 10px 10px 10px !important;
    }
    .mobile_gallery_view{
        background-color: #000;
        padding: 20px 0px;
        display: block;
    }
    .Heading{
        font-family: Amelaryas;
    font-size: 14px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 100%;
    margin: 0px;
    }

    
  }