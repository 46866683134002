/* .about_main_container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-position: center;
  flex-direction: column;
  background-size: contain;
  background-image: url("../../../public/images/AboutRec1.png");
  min-width: 100%; 
}
.about_child_component {
  display: flex;
}
.nLogo_About {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translate(-65%, -22%);
  height: 28%;
  width: 25%;
  z-index: 999;
}
.Events_text {
  display: flex;
  margin-inline: auto;
}
.aboutLeftText_events {
  font-family: Amelaryas;
  font-size: 1.9vw;
  font-weight: 400;
  letter-spacing: 0em;
  color: #ffffff;
  position: absolute;
  top: 40%;
  left: 15%;
}
.aboutLeftText_events span {
  color: #e4ac4c;
}
.event_Paragraph {
  position: absolute;
  top: 50%;
  left: 15%;
  text-align: justify;
  font-family: Helvetica;
  padding: 25px 0px 0px 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #ffffff;
  margin-inline: auto;
  width: 300px;
}
.event_page_button {
  position: absolute;
  top: 64%;
  left: 15%;
  padding: 13px 0px 0px 0px;
}
.event_page_button button {
  background-color: #000000cc;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 18px 24px 14px 24px;
  border: none;
  border-bottom: 1px solid #f2f2f2;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.resBtn {
  background-color: #ffffff;
  color: black;
}
.connect_Btn {
  margin-left: 14px;
} */

.main_container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.event_child_container {
  position: relative;
  width: 100%;
}

.image_event_main_conatiner {
  width: 100%;
  /* height: 100%; */
  height: 470px;
  object-fit: cover;
}

.my_event_picture {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.child_container {
  position: absolute;
  text-align: -webkit-center;
  top: 45%;
  /* gap: 10px; */
  transform: translate(0, -50%);
  padding: 10px;
}

.Events_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutLeftText_events_ {
  font-family: Amelaryas;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #ffffff;
  position: relative;
}
.aboutLeftText_events_ span {
  color: #e4ac4c;
  margin-right: 7px;
}
.event_Paragraph {
  font-family: Helvetica;
  width: 45%;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
}

.event_page_button {
  position: absolute;
  top: 88%;
  display: flex;
  left: 24%;
  padding: 13px 0px 0px 0px;
  gap: 13px;
}
.event_page_button button {
  background-color: #000000cc;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 18px 24px 14px 24px;
  /* border: none; */
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.resBtn {
  background-color: #ffffff;
  color: black;
}
.resBtn:hover {
  background-color: #ffffff;
  color: black;
  border: none;
}
.notorious_event_page_button {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 85%;
  gap: 10px;
  align-items: self-start;
}
@media (min-width: 1700px) and (max-width: 3000px) { 
  .image_event_main_conatiner {
    /* height: fit-content; */
    height: 532px;
    object-fit: cover;
  }
  .child_container {
    padding: 41px;
}
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  .notorious_event_page_button {
    align-items: center;
  }
  .image_event_main_conatiner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
    background-color: black;
}
  .event_Paragraph {
    font-family: Helvetica;
    width: 100%;
    color: white;
    font-size: 14px;
    text-align: center;
  }
  .aboutLeftText_events_ {
    font-size: 17px;
  }
  .main_container {
    /* height: 50vh; */
    height: 28vh;
  }
  .child_container {
    position: absolute;
    top: 55%;
    /* gap: 10px; */
    transform: translate(0, -50%);
    padding: 10px;
  }
}