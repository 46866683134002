.outerContainer {
  width: 100%;
  position: relative;
  height: auto;
  color: #ffffff;
  font-family: Helvetica;
  overflow-x: hidden;
}
.break_text {
  display: none;
}

.icons {
  color: #fff;
  font-size: 22px;
}
.scroll_top_btn {
  font-size: 13px;
  border-radius: 50px;
    width: 40px;
    position: absolute;
    right: 70px;
    height: 38px;
    top: 230px;
    background-color: #181818;
    color: #fff;
    border: navajowhite;
    border: palegoldenrod;
    border: 1px solid #fff;
}
.mobile_view{
  display: none;
}
#address_footer{
  display: none;
}
.innerContainer {
  background-color: #181818;
  height: 100%;
  padding: 50px 7vw 0px 7vw;
}
.footerItems {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 5px 0px 1.5vw 0px;
  gap: 20px;
}
.footerIcons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.getDirection {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 16px;
  text-wrap: nowrap;
}
.getDirection a {
  color: #e4ac4c;
  font-weight: 400;
  letter-spacing: 0em;
  display: inline-block;
}

.getDirection img {
  filter: invert(100%);
  width: 25px;
}
.address {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 400;
  line-height:17px;
  font-size: 16px;
  font-family: Helvetica;
}
.icon {
  filter: invert(100%);
}
.footerCenterItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-inline: auto;
  padding: 20px 0;
}
.footerCenterFirst {
  font-size: 16px;
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: space-between;
  gap: 10px;
  text-align: center;
  text-wrap: nowrap;
}
.footerCenterFirst a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 400;
  line-height: px;
  font-size: 17px;
  font-family: Helvetica;
}
.footerCenterSecond {
  display: flex;
  margin-inline: auto;
  padding-top: 1px;
  align-items: center;
  gap: 1px;
  text-wrap: nowrap;
}
.footerEnding {
  padding: 20px 7vw 0px 7vw;
  display: flex;
  justify-content: center;
  color: #d2d2d2;
  text-wrap: nowrap;
  flex-wrap: nowrap;
  margin-inline: auto;
  font-size: 16px;
}
.endItems {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}
.endItems span{
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 17px;
  line-height: 27px;
  font-weight: 400;
  cursor: pointer;
}
.centerLogo {
  width: 240px;
}

/* privacy policy css here */

.my_privacy_policy_main_class {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  background-color: #181818;
  height: max-content;
}
.my_main_content {
  text-align: justify;
  width: 100%;

}
.privacy_policy_date {
  color: #fff;
  font-family: Helvetica;
  font-size: 22px;
  font-weight: 500;
  line-height: 44px;
  text-align: start;
  margin-bottom: 18px;
}
.main_paragraph {
  font-family: Amelaryas;
  color: #ffffff;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  line-height: 70px;
}
.main_paragraph span {
  color: #e4ac4c;
}
.main_paragraph1 {
  color: #929292;
    font-family: Helvetica;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 14px;
    text-align: left;
}
.information_collect {
  color: #e4ac4c;
    text-align: start;
    font-size: 23px;
    font-weight: 500;
    line-height: 21px;
    margin: 0px;
}
.reservation_content_div {
  display: flex;
  flex-direction: column;
}
.reservation_child_content {
  color: #929292;
  font-family: Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  margin: 7px 0px 0px 0px;
}
.term_and_condition_gap_div {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media only screen and (min-width: 600px) and (max-width: 1040px) {
  .footerCenterFirst {
    font-size: 16px;
    flex-direction: column;
    margin-inline: auto;
  }
  .footerCenterSecond {
    flex-direction: column;
    font-size: 16px;
  }
  .footerEnding {
    font-size: 15px;
  }
  .endItems {
    gap: 13px;
  }
  .centerLogo {
    width: 180px;
  }
  .getDirection {
    font-size: 14px;
  }
  .getDirection img {
    width: 20px;
  }
}
@media screen and (min-width: 420px) and (max-width: 600px) {
  .address{
    font-size: 13px;
  }
  .footerCenterSecond {
    flex-direction: column;
    font-size: 12px;
  }
  .footer_icons__mlz8z {
    color: #fff;
    font-size: 16px;
}
  .endItems {
    gap: 8px;
  }
  .footerItems {
    gap: 15px;
  }
  .rectangleOutline img {
    width: 90vw;
    height: 69vh;
  }
  .fb {
    width: 14px;
  }
  .insta,
  .twitter {
    width: 25px;
  }
  .footerItems {
    align-items: start;
  }
  .getDirection {
    font-size: 19px;
  }
  .footerCenterItems {
    margin-inline: auto;
    /* width: fit-content; */
  }
  .footerCenterFirst {
    font-size: 12px;
    /* margin-inline: auto; */
    flex-direction: column;
    justify-content: center;
    margin-inline: auto;
  }
  .footerCenterSecond {
    flex-direction: column;
    font-size: 12px;
  }
  .footerEnding {
    font-size: 11px;
    /* padding-inline: 3px; */
  }
  .centerLogo {
    width: 160px;
  }
  .getDirection {
    font-size: 14px;
  }
  .getDirection img {
    width: 20px;
  }
  .fb {
    width: 13px;
  }
  .insta,
  .twitter {
    width: 18px;
  }
  /* //term and condition and also privacy and ploicy  */
  .my_main_content {
    padding-top: 5%;
    padding-inline: 3%;
  }
  .my_main_content {
    padding-top: 10%;
  }
  .main_paragraph {
    font-size: 15px;
    font-weight: 400;
    line-height: 39px;
  }
  .privacy_policy_date {
    font-size: 13px;
    font-weight: 700;
    line-height: 25px;
  }
  .main_paragraph1 {
    font-size: 11px;
    line-height: 22px;
    margin-bottom: 40px;
  }
  .information_collect {
    font-size: 14px;
    line-height: 10px;
  }
  .privacy_policy_date {
    font-size: 11px;
    font-weight: 700;
    line-height: 24px;
  }
}
@media screen and (max-width: 420px) {
  .footerCenterSecond {
    flex-direction: column;
    font-size: 12px;
  }
  /*small screen*/
  .footerItems {
    gap: 20px !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }
  .footerCenterItems {
    margin-inline: auto;
    /* width: fit-content; */
  }
  .footerCenterFirst {
    font-size: 10px;
    /* margin-inline: auto; */
    flex-direction: column;
    justify-content: center;
    margin-inline: auto;
  }
  .footerCenterSecond {
    flex-direction: column;
    font-size: 10px;
  }
  .footerEnding {
    font-size: 8px;
    margin-inline: auto;
  }
  .endItems {
    gap: 6px;
  }
  .centerLogo {
    width: 120px;
  }
  /* .getDirection {
    font-size: 12px;
  } */
  /* .getDirection img {
    width: 16px;
  } */
  .fb {
    width: 9px;
  }
  .insta,
  .twitter {
    width: 16px;
  }
  .footerItems {
    align-items: start;
    padding: 0px;
    padding-bottom: 15px;
  }
  .getDirection {
    font-size: 19px;
  }
  .footerCenterSecond {
    align-items: start;
  }
  .footerCenterItems {
    justify-content: start;
  }
  .footerCenterFirst {
    flex-direction: column;
    justify-content: start;
  }
  .address_1gdla {
    display: flex;
    align-items: start;
    text-align: start;
  }
  .footerCenterFirst {
    flex-direction: column;
    justify-content: start;
    display: flex;
    text-align: start;
  }
  .address {
    display: flex;
    align-items: start;
    text-align: start;
    margin-left: -2px;
  }
  .innerContainer {
    padding: 15px;
  }
  .footerCenterItems {
    display: contents;
    padding: 0;
  }
  .getDirection img {
    width: 15px;
    margin-left: -3px;
  }
  .icon {
    margin-right: 4px;
    margin-top: 2px;
    width: 12px;
    height: 12px;
  
  }
  .footerCenterFirst {
    gap: 0px;
    text-align: center;
  }
  .getDirection a {
    font-size: 12px;
  }
  .my_footer_mail_class {
    width: 10px;
    height: 10px;
    margin-top: 5px;
    margin-left: 3px;
  }
  .address {
    margin-left: -3px;
  }
  .footerEnding{
    padding: 2px 7vw 0px 7vw;
}
  /* //Term and condition css code here */
  .my_main_content {
    padding-inline: 3%;
  }
  .main_paragraph {
    font-size: 16px;
    line-height: 34px;
    margin-bottom: 22px;
  }
  .privacy_policy_date {
    font-size: 13px;
    line-height: 25px;
  }
  .information_collect {
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
  }
  .reservation_child_content{
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
  }
  .main_paragraph1 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }
  .my_main_content {
    padding-top: 7%;
  }
  .address {
    line-height: 19px;
    font-size: 11px;
    margin-bottom: 10px;
    text-align: center;
  }
  .footerCenterFirst a {
    font-size: 12px;
  }
  .endItems span {
    font-size: 9px;
  }

}
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .address{
    font-size: 13px;
  }
  .break_text{
    display: block;
  }
  .time_icon{
  font-size: 25px;
    margin-bottom: -3px;
    transform: translate(0px, -4px);
}
  .icons {
    color: #fff;
    font-size: 16px;
}
  .scroll_top_btn{
    border-radius: 50px;
    width: 32px;
    position: absolute;
    right: 15px;
    height: 33px;
    top: 292px;
    background-color: #181818;
    color: #fff;
    border: navajowhite;
    border: palegoldenrod;
    border: 1px solid #fff;
  }

  footer .footerItems{
    display: none;
  }
 .footerCenterFirst a{
  font-size: 14px;
  font-family: Helvetica;
  margin-left: 8px;
 } 
 .footerCenterFirst p{
  margin: 6px 0px;
  text-align: center;
  display: block;
 } 
 .line{
  display: none;
 }
 #address_footer{
  display: blockz;
}
.endItems{
  flex-wrap: wrap;
    justify-content: center;
}
.endItems span{
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}
.footerCenterSecond{
  text-wrap: wrap;
  margin-top: 9px;
}
.mobile_view{
  display: flex;
  justify-content: center;
    margin: 15px 0px;
}
.footerIcons{
  margin-right: 175px;
}
}
@media (min-width: 1700px) and (max-width: 3000px) {
  .my_main_content{
    padding-inline: 8%;
}
}
