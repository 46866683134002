/* ourmenu.module.css */

@font-face {
  font-family: Helvetica;
  src: url("../../public//fonts/Helvetica.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.outerContainer {
  background-color: rgba(29, 29, 29, 1);
  width: 100%;
  position: relative;
  overflow: hidden;
  /* height: 110vh; */
  height: max-content;
  padding: 30px;
}
.subheading{
  font-family: Amelaryas;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  line-height: 55px;
}
.innerContainer {
  width: 100%;
  position: relative;
  background-color: #1e1e1e;
  display: flex;
  align-items: center;
  gap: 6vw;
  padding: 0vw;
}

.leftSection {
  background-image: url("./Images/Epical.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
}

.shade:hover .leftImg1 {
  border: 1px solid white;
}

.shade:hover .menuBtn {
  opacity: 1;
  transform: translate(-50%, -50%); /* Center the button on hover */
}

.postion_OurMenu:hover span {
  color: white;
  font-family: Amelaryas;
  opacity: 1;
  transform: translate(-55%, -81%); /* Center the span on hover */
}

.shade {
  background: rgba(30, 30, 30, 0.9);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.leftImg1 {
  height: 43vw;
  width: 15vw;
}

.postion_OurMenu {
  position: relative;
  text-align: center;
}

.postion_OurMenu span {
  position: absolute;
  font-family: Helvetica;
  font-weight: 600;
  font-size: 22px;
  top: 76%;
  left: 16%;
  transform: translateX(-50%);
  z-index: 1;
  line-height: 30px;
  color: white;
  opacity: 0;
  width: 10%;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Add a smooth transition effect */
}

.menuBtn {
  position: absolute;
  background-color: #e4ac4c;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  border: 1px solid #f2f2f2;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  bottom: 6%;
  left: 35%;
  transform: translate(-50%, 50%);
  border-radius: 12px;
  opacity: 0;
  z-index: 9999;
}

.postion_OurMenu:hover .menuBtn {
  opacity: 1;
}

.menuBtn:hover {
  background-color: #ffffff;
  color: black;
  border: none;
}

.rightSection {
  width: 50%;
  display: flex;
  /* padding: 0vw 7vw 0vw 4vw; */
  flex-direction: column;
  text-align: justify;
  /* padding: 0vw 7vw 0vw 4vw; */
}

.Heading {
  font-family: Amelaryas;
  font-size: 28px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.textContent {
  color: #ffffff;
  width: 100%;
  font-family: Helvetica;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  font-size: 18px;
  padding-top: 20px;
  position: relative;
}
.textContent p {
  width: 84%;
}
.Heading1 {
  color: #e4ac4c;
  font-size: 28px;
  margin: 0px;
}
.carousels_story {
  width: 55%;
  display: flex;
}
.carousels_story span {
  color: #e4ac4c;
  font-family: Amelaryas;
  font-size: 28px;
}
.carousels_story p {
  font-family: Amelaryas;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  line-height: 55px;
}
@media screen and (max-width: 1440px) {
  .outerContainer {
    padding: 28px;
  }
}
/* Media Queries (Responsive Design) */
@media screen and (max-width: 1024px) {
  .innerContainer {
    gap: 1vw;
  }
  .outerContainer {
    height: fit-content !important;
    padding-bottom: 20px;
  }
  .carousels_story {
    width: 100%;
    display: flex;
  }
  .carousels_story span {
    font-size: 15px;
  }
  .carousels_story p {
    font-size: 15px;
    line-height: 32px;
  }
  .rightSection {
    width: 100%;
    display: flex;
    text-align: justify;
    align-items: center;
  }

  .textContent {
    font-size: 15px;
    line-height: 25px;
    padding-top: 0px;
  }
  .leftSection {
    width: 100%;
  }
  .innerContainer {
    flex-wrap: wrap;
  }
  .leftImg1 {
    height: 65vw;
    width: 100%;
  }
  .rightSection {
    width: 100%;
  }
  .Heading {
    text-align: center;
  }
  .textContent p {
    width: 100%;
    align-items: center;
    display: flex;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .ourmenu_postion_OurMenu__atY9O:hover span {
    color: white;
    opacity: 1;
    transform: translate(-55%, -64%) !important;
    line-height: 22px;
    font-size: 13px;
    width: 100%;
    left: 121px !important;
    text-align: left;
}
  .carousels_story {
    width: 100%;
    display: flex;
  }
  .postion_OurMenu span {
    font-size: 13px;
    top: 64%;
    left: 15%;
    width: 10%;
    line-height: 18px;
  }
  .menuBtn {
    padding: 6px 6px 6px 6px;
    width: 49%;
    left: 33%;
    font-size: 12px;
  }
  .carousels_story span {
    font-size: 15px;
  }
  .carousels_story p {
    font-size: 15px;
    line-height: 32px;
  }
  .rightSection {
    width: 100%;
    display: flex;
    text-align: justify;
    align-items: center;
  }

  .textContent {
    font-size: 15px;
    line-height: 25px;
    padding-top: 3px;
  }
  .leftSection {
    width: 100%;
  }
  .innerContainer {
    flex-wrap: wrap;
  }
  .leftImg1 {
    height: 70vw;
    width: 50vw;
  }

  .leftSection {
    width: 100%;
    overflow-x: auto;
  }

  .leftSection::-webkit-scrollbar {
    height: 1px;
    background-color: #1d1d1d;
  }
  .Heading {
    text-align: center;
  }
  .textContent p {
    width: 100%;
    align-items: center;
    display: flex;
    font-size: 13px;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 0px;
  }
}

/* @media screen and (max-width: 420px) {
  .textContent {
    font-size: 13px;
    line-height: 20px;
    top: 20px;
    text-align: center;
    width: 100%;
  }
  .rightSection {
    width: 100%;
    text-align: center;
    order: 1;
    padding: 4vw 7vw;
  }
  .leftImg1 {
    height: 300px;
    width: 100px;
  }
  .innerContainer {
    height: 100vh;
    flex-direction: column;
    gap: 50px;
  }
  .leftSection {
    width: 100%;
    order: 2;
  }
  .Heading {
    text-align: center;
    font-size: 15px;
    line-height: 30px;
  }
  .menuBtn {
    padding: 5% 5% 5% 5%;
    font-size: 8px;
    bottom: 7.5%;
    left: 15.6%;
  }
} */
@media only screen and (min-width: 360px) and (max-width: 600px) {
 .innerContainer{
  flex-direction: column-reverse;
  display: flex;
 }
 .outerContainer{
  padding: 15px;
 }
 .Heading1{
font-size: 15px;
 }
 .subheading{
  line-height: 30px;
  font-size: 17px;
 }
}

@media (min-width: 1700px) and (max-width: 3000px) {
  .textContent p {
    width: 100%;
  }
  .outerContainer {
    padding-bottom: 70px;
}
.leftImg1 {
  height: 35vw;
  width: 15vw;
}
}