.App {
  text-align: center;
  width: 100%;
  overflow: hidden;
}
ul.control-dots {
  top: 0 !important;
  left: 0 !important;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add other styles as needed */
}
/* .ant-modal-wrap {
  z-index: 9999 !important;
} */
ul.control-dots {
  left: -43% !important;
  /* transform: translateX(-50%); */
}
li.dot {
  margin-top: 90px !important;
}
/* Add these styles to GalleryPage.module.css */
.fancybox-navigation span {
  background-color: white !important; /* Change arrow background color to white */
  color: black !important; /* Change arrow icon color to black */
}

/* slider */
.modal-slide-up .modal-dialog {
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
  width: 25% !important;
  height: 300px !important;
  border-radius: 0 !important;
}
.modal-slide-up .modal-content {
  border-radius: 0;
  background-color: #262626;
  border: 1px solid white;
  height: 35rem;
  /* height: max-content; */
}
.modal-slide-up .modal.show .modal-dialog {
  transform: translateY(0);
}

.modal-header {
  border-bottom: none !important;
}
/* .close_button1{
  color: aliceblue !important;
}
.close_button{
  color: aliceblue !important;
}
.modal-header button{
  color: aliceblue;
} */

/* .custom-modal-slide-up .modal-dialog {
  width: 10% !important;
}

.custom-modal-slide-up .modal-content {
} */
/* .modal-slide-up .modal-content .btn-close {
  color: white !important;
} */

.customDot {
  width: 10px;
  height: 10px;
  background-color: white; /* Change the color as needed */
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; /* Adjust the spacing between dots if necessary */
}
/* .slick-dots {
  top: 0 !important;
  margin-left: 6.6% !important;
  margin-top: 0.5%;
  top: 20%;
} */
.slick-active > .customDot {
  background-color: red !important;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  top: 25%;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.customDot {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  margin-right: 5px; /* Adjust as needed */
}

.activeDot {
  background-color: yellow !important;
}
/* //PopUp provide color */
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel {
  background-color: #131212 !important;
  color: white !important;
}
:where(.css-p7e5j5).ant-picker-dropdown .ant-picker-header {
  color: white !important;
}
:where(.css-p7e5j5).ant-picker-dropdown .ant-picker-content th {
  color: white !important;
}
:where(.css-p7e5j5).ant-picker-dropdown .ant-picker-cell {
  color: white !important;
}
:where(.css-p7e5j5).ant-picker-dropdown .ant-picker-today-btn {
  color: #1677ff;
  text-decoration: none !important;
  color: white;
}
:where(.css-p7e5j5).ant-picker .ant-picker-input > input {
  color: white !important;
}
:where(.css-p7e5j5).ant-picker-dropdown .ant-picker-header button {
  color: white !important;
}
:where(.css-p7e5j5).ant-picker-focused.ant-picker {
  border-color: #262626 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #767676 !important;
}
:where(.css-p7e5j5).ant-picker:hover {
  border-color: #767676 !important;
}
:where(.css-p7e5j5).ant-picker .ant-picker-suffix {
  color: #939393 !important;
}
/* //End css color color */
/* //For time css */
:where(.css-p7e5j5).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: white !important;
}
:where(.css-p7e5j5).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: #131212 !important;
}
:where(.css-p7e5j5).ant-btn-primary:disabled,
:where(.css-p7e5j5).ant-btn-primary.ant-btn-disabled {
  color: white !important;
}
.ant-picker-now-btn {
  text-decoration: none !important;
  color: white !important;
}
:where(.css-p7e5j5).ant-btn-primary {
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  background-color: #131212 !important;
}
/* //time css end */
:where(.css-dev-only-do-not-override-2q8sxy).ant-modal .ant-modal-content {
  background-color: #262626;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-modal .ant-modal-header {
  color: rgba(0, 0, 0, 0.88) !important;
  background: #262626;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
  margin-top: 20px !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker:hover {
  border-color: #595959 !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-focused.ant-picker {
  border-color: #595959 !important;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-modal .ant-modal-close {
  color: white !important;
  cursor: pointer !important;
}
/* :where(.css-dev-only-do-not-override-p7e5j5).ant-modal .ant-modal-content {
  background-color: #262626 !important;
} */
/* :where(.css-dev-only-do-not-override-p7e5j5).ant-modal .ant-modal-content {
  position: relative;
  background-color: #262626 !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 25px !important;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 20px 24px;
} */
/* :where(.css-dev-only-do-not-override-p7e5j5).ant-modal .ant-modal-close {
  color: white !important;
} */
/* .tharmal_modal .ant-modal .ant-modal-content {
  background-color: #262626 !important;
  border-radius: 25px !important;
} */

.slider_outer .carousel-control-prev,
.slider_outer .carousel-control-next {
  display: none !important;
}
.ant-modal .ant-modal-content {
  position: relative;
  background-color: #262626 !important;
  border-radius: 25px !important;
  background-clip: padding-box;
  border: 0;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 20px 24px;
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 100 !important;
}
.ant-modal .ant-modal-close {
  color: white !important;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-modal .ant-modal-title {
  color: white;
  font-family: Helvetica;
  font-size: 25px;
  font-weight: 500;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-picker .ant-picker-suffix {
  color: white !important;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-picker:hover {
  border-color: #595959 !important;
}
/* :where(.css-dev-only-do-not-override-2q8sxy).ant-modal .ant-modal-content {
  border-radius: 25px !important;
  border-radius: 25px !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-modal .ant-modal-content {
  background-color: #262626 !important;
  border-radius: 25px !important;
} */
:where(.css-dev-only-do-not-override-2q8sxy).ant-picker-focused.ant-picker {
  border-color: #595959 !important;
  box-shadow: none !important;
  outline: 0 !important;
}
img.ant-image-preview-img {
  width: 40% !important;
}
.container-fluid {
  background-color: #181818;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker .ant-picker-suffix {
  display: none !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker .ant-picker-suffix {
  display: none !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-focused.ant-picker {
  border-color: #595959 !important;
  box-shadow: none !important;
  outline: 0 !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker
  .ant-picker-input
  > input:hover {
  color: white !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker
  .ant-picker-input
  > input {
  color: white !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown
  .ant-picker-content
  th {
  color: white !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown
  .ant-picker-cell {
  color: #ffffff87 !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown
  .ant-picker-cell-disabled {
  color: #ffffff96 !important;
  pointer-events: none;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown
  .ant-picker-today-btn {
  color: #ffffff !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown
  .ant-picker-cell-in-view {
  color: white !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown
  .ant-picker-header
  button {
  color: white !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: #ffffff !important;
}
:where(.css-dev-only-do-not-override-p7e5j5) a {
  color: #ffffff !important;
  text-decoration: none;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-btn-primary:disabled,
:where(.css-dev-only-do-not-override-p7e5j5).ant-btn-primary.ant-btn-disabled {
  color: #ffffff !important;
  font-size: 10px !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #6c757dc7 !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-col {
  margin-right: 10px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  backdrop-filter: brightness(150%);
  margin: 0px;
  margin-right: 20px !important;
}
.carousel-control-next,
.carousel-control-prev {
  /* right: 0; */
  display: block !important;
}
#carouselExampleControls img {
  height: 400px !important;
  object-fit: cover !important;
}
/* #carouselExampleControls img, #speakeasy img{
  height: 400px !important;
  object-fit: cover !important;
} */
@media (max-width: 768px) {
  :where(.css-dev-only-do-not-override-p7e5j5).ant-modal {
    position: relative;
    top: 20px !important;
    width: 348px !important;
  }
  .card img {
    width: 100% !important;
  }
  .ant-image {
    width: 100% !important;
  }
  :where(.css-dev-only-do-not-override-p7e5j5).ant-image-preview-root
    .ant-image-preview-img-wrapper
    > * {
    pointer-events: auto;
    width: 89% !important;
  }
}
