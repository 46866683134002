
/* .main_container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
} */
.main_container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.event_child_container {
  position: relative;
  width: 100%;
}

.image_event_main_conatiner {
  width: 100%;
  /* height: 100%; */
  height: 470px;
  object-fit: cover;
}

.my_event_picture {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.child_container {
  position: absolute;
  text-align: -webkit-center;
  top: 50%;
  /* gap: 10px; */
  transform: translate(0, -50%);
  padding: 10px;
}

.Events_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutLeftText_events_ {
  font-family: Amelaryas;
  font-size: 33px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #ffffff;
  position: relative;
}
.aboutLeftText_events_ span {
  color: #e4ac4c;
  margin-right: 7px;
}
.event_Paragraph {
  font-family: Helvetica;
  width: 45%;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
}

.event_page_button {
  position: absolute;
  top: 88%;
  display: flex;
  left: 24%;
  padding: 13px 0px 0px 0px;
  gap: 13px;
}
.event_page_button button {
  background-color: #000000cc;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 18px 24px 14px 24px;
  /* border: none; */
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.resBtn {
  background-color: #ffffff;
  color: black;
}
.resBtn:hover {
  background-color: #ffffff;
  color: black;
  border: none;
}
.notorious_event_page_button {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 85%;
  gap: 10px;
  align-items: self-start;
}





.main_container_gallery {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.images_of_notorious {
  font-family: Amelaryas;
  font-weight: 400;
  font-size: 28px;
  /* margin-top: 40px; */
  line-height: 30px;
  letter-spacing: 0em;
  color: #ffffff;
  margin: 45px;
}
.images_of_notorious span {
  color: #e4ac4c;
  margin: 5px;
}
/* .event_child_container {
  position: relative;
  width: 100%;
} */

/* .image_event_main_conatiner {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

/* .my_event_picture {
  position: relative;
  width: 100%;
  overflow: hidden;
} */

/* .child_container {
  position: absolute;
  top: 40%;
  transform: translate(0, -50%);
  padding: 10px;
} */

.Events_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutLeftText_events {
  font-family: Amelaryas;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #ffffff;
  position: relative;
}

.aboutLeftText_events span {
  color: #e4ac4c;
}
/* .event_Paragraph {
  font-family: Helvetica;
  width: 45%;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
} */

.event_page_button {
  position: absolute;
  top: 88%;
  display: flex;
  /* left: 24%; */
  padding: 13px 0px 0px 0px;
  gap: 13px;
}
.event_page_button button {
  background-color: #000000cc;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 18px 24px 14px 24px;
  /* border: none; */
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.resBtn {
  background-color: #ffffff;
  color: black;
}
.resBtn:hover {
  background-color: #ffffff;
  color: black;
  border: none;
}
/* .notorious_event_page_button {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 70%;
  gap: 10px;
  align-items: center;
} */

/* ///Next container css here */
.aboutOrganizeEventConatiner {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: rgb(44 44 44);
  padding: 50px 0;
}
.aboutOrganizeEventConatiner_1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: rgb(32 32 32);
  padding: 50px 0;
}
.my_organize_content {
  color: white;
}

.organize_event_direction {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.Heading {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Heading h1 {
  font-family: Amelaryas;
  font-size: 28px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0em;
  color: #e4ac4c;
}

.Heading p {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #ffffff;
  width: 60%;
  margin: 0 auto 25px;
}

/* .Heading > div {
    padding: 20px;
  } */
.leftContainer img {
  width: 100%;
  object-fit: cover;
  height: 65vh;
}
.leftContainer div {
  padding-top: 25px;
  color: #ffffff;
  text-align: start;
}
.leftContainer h2 {
  font-family: Abril Fatface;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}
.leftContainer h3 {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 0px;
}
.leftContainer p {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 15px;
  color: #8f8f8f;
  padding-right: 10px;
}
.leftContainer a {
  text-decoration: none;
  color: #e4ac4c;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}

/* .about_us_scroll_container {
      display: flex;
      width: 100%;
    } */

/* .about_us_scroll_container::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    color: transparent !important;
  }
  .card {
    text-align: start;
    margin-bottom: 10px;
  }
  .card img {
    width: 100%;
    height: auto;
    max-height: 138px;
    object-fit: contain;
  }
  
  .about_us_scroll_container {
    height: 500px;
    overflow-y: auto;
    color: #ffffff;
  }
  
  .about_us_scroll_container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .class{
      color: #ffffff;
  }
  @media (max-width: 768px) {
    .about_us_scroll_container {
      height: auto;
    }
  
    .card img {
      max-height: none; 
    }
  } */

/* .about_us_scroll_container ::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      color: transparent !important;
    }
    
    .card {
      text-align: start;
      margin-bottom: 10px;
    }
    
    .card img {
      width: 100%;
      height: auto;
      max-height: 138px;
      object-fit: contain;
    }
    
    .about_us_scroll_container {
      height: 500px;
      overflow-y: auto;
      color: #ffffff;
    }
    
    .about_us_scroll_container {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    
    .about_us_scroll_container ::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      color: transparent !important;
    }
    
    .about_us_scroll_container {
      color: #ffffff;
    }
     */

/* styles.css */

/* styles.css */

.about_us_scroll_container {
  /* height: 500px; */
  /* overflow-y: auto; */
  color: #ffffff;
}

/* .about_us_scroll_container::-webkit-scrollbar {
      width: 0; 
    } */

.scrollContent {
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
}

.card {
  text-align: start;
  margin-bottom: 10px;
}

.card img {
  width: 100%;
  height: auto;
  /* max-height: 138px; */
  object-fit: cover;
}
/* ////Kkkkkkkkkk */
/* style.css */

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.fancybox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.fancybox-container a {
  margin: 10px;
  text-decoration: none;
  color: #333;
}

.fancybox-container img {
  width: 200px;
  height: 150px;
  border: 2px solid #333;
  border-radius: 8px;
}

.fancybox-container img:hover {
  border-color: #ff6600;
}
.fancybox-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(30, 30, 30, 0), rgba(30, 30, 30, 0.8));
  z-index: 1;
}
.card:hover img {
  border: 2px solid #fff;
}
.card img {
  margin-bottom: 20px;
}
.rounded {
  position: relative;
  width: 100%;
  /* margin: 12px; */
  margin-bottom: 20px;
  overflow: hidden;
}
.rounded::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(30, 30, 30, 0), rgba(30, 30, 30, 0.8));
  z-index: 1;
}
.rounded:hover::before {
  display: none;
}
.rounded:hover{
  border: 2px solid white;
}
.fancybox-navigation span {
  background-color: white !important; /* Change arrow background color to white */
  color: black !important; /* Change arrow icon color to black */
}
@media screen and (max-width: 768px) {
  .image_event_main_conatiner {
    display: none;
}
  .images_of_notorious {
    font-size: 17px;
    margin: 6px 11px 37px 0px;
  }
  .main_container_gallery {
    margin-top: -2px;
    padding-top: 12px;
  }
  .event_Paragraph {
    font-family: Helvetica;
    width: 100%;
    color: white;
    font-size: 14px;
    text-align: center;
  }
  .event_Paragraph p{
    margin: 0px;
  }
  .aboutLeftText_events_ {
    font-size: 17px;
    text-align: center;
  }
  .main_container {
    /* height: 50vh; */
    height: 22vh;
    padding: 34px 0px 22px;
  }
  .child_container {
    position: absolute;
    top: 40%;
    /* gap: 10px; */
    transform: translate(0, -50%);
    padding: 10px;
  }
  .notorious_event_page_button {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 85%;
    gap: 10px;
    align-items: center;
  }
  
}