.map_main_container{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgba(32, 32, 32, 1);
    height: 50vh;
    margin-top: 10px;
}
.map_mid_container{
    display: flex;
}
.contact_us_main_class {
    background-color: rgb(24 24 24);
}
@media (min-width: 1700px) and (max-width: 3000px) {
  .contact_us_main_class {
    padding-top: 25px;
    padding-bottom: 40px;
  }
}