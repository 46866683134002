.about_by_main_container {
  display: flex;
  width: 100% !important;
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  background-color: rgba(32, 32, 32, 1);
  flex-direction: column;
  padding-bottom: 40px;
}
.about_by_mid_container {
  display: flex;
  /* align-self: self-start; */
  flex-direction: column;
  align-items: center;
}

.aboutLeftText_ {
  font-family: Amelaryas;
  /* font-size: 1.9vw; */
  font-weight: 400;
  font-size: 28px;
  margin-top: 40px;
  /* line-height: 30px; */
  letter-spacing: 0em;
  color: #ffffff;
}
.aboutLeftText_ span {
  color: #e4ac4c;
}
.about_by_Paragraph {
  text-align: center;
  font-family: Helvetica;
  padding: 4px 0px 0px 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  /* text-align: center; */
  color: #ffffff;
  margin-inline: auto;
  z-index: 100;
  width: 62%;
}
.about_notorious_mid_container {
  display: flex;
  width: 100%;
  text-align: center;
  /* justify-content: space-evenly; */
  justify-content: center;
  flex-direction: row;
  /* padding: 11px 11px 11px 11px; */
  gap: 40px;
  /* flex-wrap: wrap; */
}
/* .about_notorious_paragraph{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px;
} */
.about_notorious_paragraph1 {
  font-family: Helvetica;
  padding: 4px 0px 0px 0px;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  /* margin-top: 10px; */
  margin-top: 15%;
  /* text-align: center; */
  color: rgba(255, 255, 255, 1);
  margin-inline: auto;
  z-index: 100;
  /* width: 64%; */
  text-align: justify;
}

.about_notorious_paragraph2 {
  font-family: Helvetica;
  padding: 4px 0px 0px 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  margin-top: 50px;
  /* text-align: center; */
  color: rgba(255, 255, 255, 1);
  margin-inline: auto;
  z-index: 100;
  /* width: 64%; */
  text-align: justify;
  margin-top: 21px;
}
.about_notorious_pic {
  max-width: 100%;
  height: auto;
  margin-top: 1.5%;
}

.about_img {
  width: 486px;
  height: 344px;
  margin: 7px 0px;
}
.about_notorious_paragraph_second_section {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 50px;
}
.about_notorious_paragraph3 {
  color: rgba(255, 255, 255, 1);
  font-family: Helvetica;
  padding: 4px 0px 0px 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  margin-inline: auto;
  z-index: 100;
  width: 47%;
  /* text-align: center;  */
  text-align: justify;
}
.about_notorious_paragraph4 {
  color: rgba(255, 255, 255, 1);
  font-family: Helvetica;
  padding: 4px 0px 0px 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  margin-inline: auto;
  z-index: 100;
  width: 47%;
  /* text-align: center;  */
  margin-top: 50px;
  text-align: justify;
}
@media (min-width: 1700px) and (max-width: 3000px) {
  .aboutLeftText_ {
    margin-top: 60px;
  }
  .about_img {
    width: 570px;
    height: 384px;
    margin: 0;
  }
  .about_notorious_mid_container {
    margin-top: 15px;
  }
  .about_by_Paragraph {
    margin-top: 25px;
  }
}
@media (max-width: 768px) {
  .about_notorious_paragraph1,
  .about_notorious_paragraph2 {
    font-size: 13px;
    line-height: 22px;
    padding: 0px 14px;
    text-align: center;
  }
  .aboutLeftText_ {
    font-size: 14px;
    line-height: 30px;
    margin: 0px;
  }
  .about_by_mid_container {
    padding: 14px 14px 0px 14px;
  }
  .about_by_Paragraph {
    width: 100%;
    text-align: center;
    /* text-align: justify; */
    line-height: 22px;
  }
  .about_notorious_paragraph3 {
    width: 69%;
    text-align: center;
    text-align: justify;
  }
  .about_notorious_paragraph4 {
    width: 69%;
    text-align: center;
    text-align: justify;
  }
  .about_notorious_paragraph_second_section {
    width: 100%;
  }
  .about_by_Paragraph {
    font-size: 13px;
  }
  .about_notorious_mid_container {
    flex-wrap: wrap;
    gap: 18px;
  }
  .about_notorious_pic {
    margin: 0px;
  }
  .ant-image {
    width: 100%;
  }
  .about_img {
    /* width: 486px; */
    width: 100%;
    height: 344px;
    margin: 0px;
  }
  .ant-image {
    width: 100%;
  }
}
