/* @font-face {
  font-family: Amelaryas;
  src: url("/fonts/Amerlaryas.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Helvetica;
  src: url("/fonts/Helvetica.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */
/* fonts.module.css */

@font-face {
  font-family: "Amelaryas";
  src: url("../../public/fonts/Amerlaryas.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("../../public/fonts/Helvetica.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.aboutLeftContent {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  width: 80%;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  z-index: 100;
  margin: 0px;
}
.resBtn:hover a {
  color: #000;
}
.outerContainer {
  width: 100%;
  position: relative;
  /* min-height: 100vh; */
}
video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.videocontainer {
  height: 100vh;
  position: relative;
}
.herobgvideo {
  position: relative;
  z-index: 9;
}

.heroShade {
  /* background-color: rgba(182, 179, 179, 0); */
  background-image: url("./Images/heroshade.svg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.innerContainer {
  background-image: url("../../public/images/home_img.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  height: 100vh;
}
.heroHeading {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-inline: 20px;
}
.heroHeading img {
  width: 50vw;
  min-width: 350px;
}
.heroText {
  color: #ffffff;
  font-family: Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  padding-inline: 10px;
  padding-top: 12px;
}
.heroHeadingBtn {
  display: flex;
  gap: 30px;
  margin-inline: auto;
  padding-top: 20px;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.heroHeadingBtn button {
  background-color: #000000cc;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 14px 24px 14px 24px;
  /* border: none; */
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.resBtn:hover {
  background-color: #e4ac4c;
  color: white;
}
@media only screen and (max-width: 1024px) {
  .innerContainer {
    height: 70vh;
    background-position: top;
  }
}
@media only screen and (min-width: 420px) and (max-width: 768px) {
  .heroHeadingBtn {
    gap: 14px;
    top: 64%;
    left: 50%;
  }
  .heroHeadingBtn button {
    font-size: 13px;
    padding: 10px 10px 10px 10px;
  }
  .innerContainer {
    height: 70vh;
    background-position: top;
  }
  .heroText {
    font-size: 18px;
  }
  .nLogo {
    height: 19% !important;
    width: 18% !important;
  }
  .aboutLeftContent {
    line-height: 19px !important;
  }
  .aboutLeftContent {
    font-size: 11px !important;
  }
  .aboutText {
    padding: 0px 56px 11px 34px !important;
  }
  .heroHeadingBtn button {
    font-size: 17px !important;
    padding: 14px 14px 14px 14px !important;
  }
  ._menuBtn_13odj_515 {
    font-size: 17px !important;
    padding: 14px 14px 14px 14px !important;
  }
}
@media only screen and (max-width: 420px) {
  .aboutLeftContent {
    font-size: 13px;
    line-height: 20px;
  }
  .heroHeading img {
    width: 90vw;
    min-width: 212px;
  }
  .heroText {
    font-size: 13px;
    line-height: 27px;
  }
  .innerContainer {
    height: 70vh;
    background-position: top;
  }
  .heroHeadingBtn {
    gap: 14px;
    top: 64%;
    left: 50%;
  }
  .heroHeadingBtn button {
    font-size: 13px;
    padding: 10px 10px 10px 10px;
  }
}

/* about home  */

.aboutSection {
  width: 100%;
  height: 110vh;
  display: flex;
  overflow: hidden;
  justify-content: center;
}
.aboutLeft {
  width: 45%;
  background-image: url("../../public/images/aboutimg1.svg");
  background-position: left;
  /* filter: grayscale(1); */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutRight {
  width: 55%;
  position: relative;
  /* height: 150vh; */
  /* filter: contrast(120%);
  background-image: url("/images/aboutimg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
}
.aboutRightInside {
  position: relative;
  /* height: 150vh; */
  filter: contrast(120%);
  height: 100%;
  width: 100%;
  background-image: url("../../public/images/aboutimg.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutRight img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.aboutShade {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.aboutLeftShade {
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.75); */
  background-color: rgba(34, 32, 32, 0.9);
  filter: grayscale(100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center; */
  /* flex-direction: column; */
}
.outline {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 80%;
  padding-left: 5vw;
}

.nLogo {
  position: absolute;
  top: 38%;
  left: 47%;
  transform: translate(-65%, -22%);
  height: 44%;
  width: 17%;
  z-index: 999;
  /* width: 100%;
}
.aboutLeftText {
  font-family: Amelaryas;
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #ffffff;
  padding: 30px 0px;
  text-align: left;
}
.aboutLeftText span {
  color: #e4ac4c;
}
.aboutLeftContent {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  width: 76%;
  line-height: 30px;
  letter-spacing: 0em;
  /* padding: 27px; */
  /* text-align: center; */
  text-align: left;
  color: #ffffff;
  /* margin-inline: auto; */
  /* width: 300px; */
  z-index: 100;
}
.aboutText {
  /* background: #000; */
  width: 85%;
  /* padding-top: 20px; */
  margin-inline: auto;
  z-index: 999;
  display: flex;
  padding: 0px 112px 7px 78px;
  flex-direction: column;
}
.aboutLeftText {
  font-family: Amelaryas;
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #ffffff;
  padding: 30px 0px;
  text-align: left;
}
.aboutLeftText span {
  color: #e4ac4c;
}
.aboutBtn {
  padding-top: 1vw;
  text-align: start;
}
.menuBtn {
  /* margin-top: 20px; */
  z-index: 999;
  /* text-align: center; */
  background-color: transparent;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 18px 24px 14px 24px;
  /* border: none; */
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.menuBtn:hover {
  background-color: #e4ac4c;
  color: white;
}
@media only screen and (max-width: 1440px) {
  .aboutText {
    padding: 0px 18px 7px 70px;
  }
}
@media only screen and (max-width: 1024px) {
  .textContent {
    font-size: 18px;
  }
  /* .innerContainer {
    height: 70vh;
    background-position: top;
  } */
  .aboutText {
    padding: 0px 10px 7px 29px;
  }
  .aboutLeftText {
    font-size: 21px;
  }
}
@media only screen and (min-width: 420px) and (max-width: 768px) {
  .innerContainer_home {
    height: 70vh;
  }
  .aboutBtn {
    text-align: center;
  }
  .aboutLeft,
  .aboutText,
  .aboutLeftContent {
    width: 100% !important;
  }

  .aboutSection {
    height: fit-content;
  }
  .outline {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50% !important;
    left: 47% !important;
    width: 100% !important;
    height: 100% !important;
    padding-left: 5vw !important;
  }

  .nLogo {
    display: none;
  }
  .aboutRight {
    width: 100% !important;
    display: none;
  }

  .heroHeadingBtn {
    gap: 14px;
    top: 71%;
    left: 50%;
  }
  .heroHeadingBtn button {
    font-size: 13px;

    padding: 10px 10px 10px 10px;
  }
  /* .innerContainer {
    height: 70vh;
    background-position: top;
  } */
  .heroText {
    font-size: 18px;
    line-height: 30px;
  }
  .nLogo {
    height: 19% !important;
    width: 18% !important;
  }
  .aboutLeftContent {
    line-height: 19px !important;
  }
  .aboutLeftContent {
    font-size: 14px !important;
  }
  .aboutText {
    padding: 0px 34px 11px 34px !important;
  }
  .heroHeadingBtn button {
    font-size: 13px !important;
    padding: 10px 10px 10px 10px !important;
  }
  ._menuBtn_13odj_515 {
    font-size: 17px !important;
    padding: 14px 14px 14px 14px !important;
  }
  /* .aboutLeftShade {
    height: -webkit-fill-available;
  } */
  .menuBtn {
    font-size: 13px;
    padding: 10px 10px 10px 10px;
  }
  .aboutLeftText {
    font-family: Amelaryas;
    font-size: 15px;
    text-align: center;
    padding: 17px 0px 6px 0px;
  }
  /* .outerContainer {
    min-height: max-content;
    margin-top: 52%;
  } */
  .outerContainer {
    min-height: max-content;
    margin-top: 0%;
  }
}
@media only screen and (max-width: 420px) {
  .outerContainer {
    width: 100%;
    position: relative;
    min-height: auto;
  }
  .heroHeadingBtn button {
    font-size: 14px !important;
    padding: 10px 10px 10px 10px !important;
  }
  ._menuBtn_13odj_515 {
    font-size: 17px !important;
    padding: 14px 14px 14px 14px !important;
  }
  /* .aboutLeftShade {
    height: -webkit-fill-available;
  } */
  .menuBtn {
    font-size: 14px;
    padding: 10px 10px 10px 10px;
    margin: 5px 8px 8px;
  }
  .aboutLeftText {
    font-family: Amelaryas;
    font-size: 15px;
    text-align: center;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .aboutLeftContent {
    font-size: 13px;
    line-height: 20px;
  }
  .heroHeading img {
    width: 66vw;
    min-width: 212px;
  }
  .heroText {
    font-size: 13px;
    line-height: 27px;
  }
  .innerContainer_home {
    height: 70vh;
    background-position: top;
  }
  .heroHeadingBtn {
    gap: 14px;
    top: 72%;
    left: 44%;
    padding-top: 8px;
    transform: translate(-40%, -30%);
  }
  .heroHeadingBtn button {
    font-size: 13px;
    padding: 10px 10px 10px 10px;
  }
  /* kkk */
  .aboutLeft,
  .aboutText,
  .aboutLeftContent {
    width: 100% !important;
  }
  .outline {
    left: 47% !important;
    height: 100% !important;
  }

  .nLogo {
    display: none;
  }
  .aboutRight {
    width: 100% !important;
    height: 32vh;
  }
  .aboutText {
    padding: 17px 15px 9px 12px;
  }
  .aboutLeft {
    height: max-content;
  }
  .aboutSection {
    height: max-content;
    display: flex;
    flex-direction: column-reverse;
  }
  .aboutLeftContent {
    padding: 10px !important;
  }
  .aboutLeftText {
    padding: 34px 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .aboutBtn {
    padding-top: 0vw;
    text-align: center;
  }
  /* .outerContainer {
    min-height: max-content;
} */
}
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .heroHeadingBtn {
    left: 43%;
  }
  .menuBtn {
    font-size: 14px;
    padding: 10px 10px 10px 10px;
    margin: 5px 8px 8px;
  }
  .aboutLeftContent p {
    margin: 0px;
    text-align: center;
  }
  .heroHeading {
    top: 40%;
    width: 393px;
  }
  video {
    width: 100%;
    height: 293px;
    object-fit: cover;
  }

  .videocontainer {
    height: 291px;
    position: relative;
    background-color: #000;
  }
  .heroShade {
    top: 69% !important;
  }
  .HomeFormInput_home {
    display: flex;
    flex-direction: column;
  }
  .outline {
    display: none;
  }
  /* .about .outerContainer {
    width: 100%;
    position: relative;
    min-height: auto;
    margin-top: 66%;
} */

  .about .outerContainer {
    width: 100%;
    position: relative;
    min-height: auto;
    /* margin-top: 53%; */
  }
  .heroText {
    font-size: 13px;
    line-height: 20px;
    margin: 0px;
  }
  .image_mask_paragraph {
    margin-bottom: 10px;
  }
  .heroShade {
    top: 47%;
    /* display: none; */
    height: 229px;
    position: relative;
  }
  .videocontainer .heroShade {
    background-color: #000;
  }
  .aboutSection {
    height: max-content;
    display: flex;
    flex-direction: column;
  }
}
.heroHeadingBtn a {
  color: #fff;
  text-decoration: none;
}
@media (min-width: 1700px) and (max-width: 3000px) {
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .videocontainer {
    height: 100vh;
    position: relative;
  }
  .outline {
    height: 75vh !important;
  }
  .aboutText {
    padding: 107px !important;
    width: 82% !important;
  }
  .aboutSection {
    height: 92vh !important;
  }
  .outerContainer {
    min-height: 92vh;
  }
  .aboutLeftContent {
    line-height: 30px;
    width: 90%;
  }
  .aboutSection {
    width: 100%;
    height: 93vh;
    display: flex;
    overflow: hidden;
    justify-content: center;
  }
}
