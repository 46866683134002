@font-face {
  font-family: Helvetica;
  src: url("../../public//fonts/Helvetica.ttf");
}
.outerContainer {
  width: 100%;
  position: relative;
  min-height: 100vh;
}
.heroShade {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 115vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.rectangleOutline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.rectangleOutline img {
  width: 86vw;
  height: 100vh;
}
.innerContainer {
  background-image: url("./Images//Epical.svg");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  height: 115vh;
}
.HomeForm {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 100%;
}
.HomeFormText {
  color: #fff;
  font-family: Helvetica;
  font-size: 30px;
  padding: 0px 7vw;
  /* font-size: 2.5vw; */
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  /* text-align: center; */
  margin-inline: auto;
  width: fit-content;
  /* background-color: red; */
  text-align: center;
  /* height: 120px; */
}
.HomeFormInput_home {
  display: flex;
  padding: 40px 0px 0px 0px;
  margin-inline: auto;
  gap: 0;

  /* width:50%; */
}
.error{
  color: #ff0000;
  font-size: 16px;
  font-style: italic;
  font-family: sans-serif;
 }

.HomeFormInputField_home {
  padding: 18px 31px 18px 31px;
  /* min-height: 57px; */
  background-color: #262626;
  position: relative;
  width: 82%;
  font-size: 16px;
  border: 1px solid #ffffff;
  color: #e3e3e3;
  outline-color: transparent;
  margin-inline: auto;
  height: 47px;
}
.HomeFormInputField_home::placeholder {
  color: #595959;
}

.HomeFormBtn_home {
  background: #000000;
  color: #ffffff;
  min-width: 131px;
  padding: 0px 8px 0px 8px;
  border: 1px solid #ffffff;
  /* cursor: pointer; */
  font-size: 18px;
  height: 47px;
  padding: 0px 8px;
  line-height: 9px;
}
@media screen and (min-width: 420px) and (max-width: 768px) {
  .HomeFormText {
    font-size: 15px;
  }
}
@media screen and (max-width: 420px) {
  .HomeFormText {
    font-size: 29px;
    line-height: 45px;
  }
  .HomeFormInputField {
    outline: none;
  }
  .HomeFormInput {
    flex-direction: column;
    margin-inline: auto;
    justify-content: center;
    gap: 30px;
  }
  .HomeFormBtn {
    width: fit-content;
    margin-inline: auto;
    font-size: 14px;
  }
  .HomeFormInput_home {
    padding: 40px 7px 60px 7px;
}

}
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .error{
    color: #f90000;
    font-size: 17px;
    background-color: #a4989842;
    font-weight: 600;
   }
  .HomeFormInput_home{
    display: flex;
    flex-direction: column;
    background-color:#a4989866;
    padding: 27px 0px 21px 0px;
    margin: 10px 0px 0px;
  }
  .HomeFormInputField_home{
    width: 85%;
    background-color: #fff;
  }
  .HomeFormBtn_home{
    width: 112px;
    margin: 17px auto 0px;
    border-radius: 13px;
   
  }
  .rectangleOutline img {
    width: 86vw;
    height: 45vh;
    display: none;
  }
  .innerContainer {
    height: 69vh;
  }
  .outerContainer{
    min-height: 57vh;
  }
  .HomeFormText {
    font-size: 28px;
    line-height: 40px;
    border: 2px solid white;
    margin: 0px 22px;
    padding: 18px 0vw 0px;
    display: flex;
    flex-direction: column;
  }
  .HomeFormText p{
   margin: 0px;
  }
}
@media (min-width: 1700px) and (max-width: 3000px) {
  .rectangleOutline img {
    width: 86vw;
    height: 89vh;
    padding-bottom: 6vh;
  }
  .innerContainer {
    position: relative;
    height: 100vh;
  }
  .heroShade {
    height: 100vh;
  }
  .outerContainer {
    width: 100%;
    position: relative;
    min-height: 0vh;
    height: 95vh !important;
  }
  .HomeFormText {
    margin-bottom: 60px;
  }
}