/* .contact_us_main_container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-position: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("../../../public/images/contactus.png");
}
.contact_us_child_component{
    display: flex;
}
.nLogo_Events {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-65%, -22%);
    height: 28%;
    width: 25%;
    z-index: 999;
  }
  .Events_text {
    display: flex;
    margin-inline: auto;
  }
  .aboutLeftText_events {
    font-family: Amelaryas;
    font-size: 1.9vw;
    font-weight: 400;
    letter-spacing: 0em;
    color: #ffffff;
    position: absolute;
    top: 40%;
    left: 15%;
  }
  .aboutLeftText_events span {
    color: #e4ac4c;
  }
  .event_Paragraph {
    position: absolute;
    top: 50%;
    left: 15%;
    font-family: Helvetica;
    padding: 25px 0px 0px 0px;
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #ffffff;
    margin-inline: auto;
    width: 300px;
    z-index: 100;
  }
  .event_page_button{
      position: absolute;
      top: 64%;
      left: 15%;
      padding: 13px 0px 0px 0px;
      
  }
  .event_page_button button{
  background-color: #000000cc;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 18px 24px 14px 24px;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
  }
  .resBtn{
    background-color: #000000cc;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: #ffffff;
    font-family: Helvetica;
    padding: 18px 24px 14px 24px;
    border: none;
    border-bottom: 1px solid #f2f2f2;
    min-height: 17px;
    cursor: pointer;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;;
  }
  .resBtn:hover {
    background-color: #ffffff;
    color: black;
  }
  .connect_Btn{
      margin-left: 14px;
  } */

.main_container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.event_child_container {
  position: relative;
  width: 100%;
}

.image_event_main_conatiner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.my_event_picture {
  position: relative;
  width: 100%;
  height: 470px;
  overflow: hidden;
}

.child_container {
  position: absolute;
  top: 40%;
  /* gap: 10px; */
  transform: translate(0, -50%);
  padding: 10px;
}

.Events_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutLeftText_contact_us {
  font-family: Amelaryas;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #ffffff;
  margin-right: 16%;
}
.aboutLeftText_contact_us span {
  color: #e4ac4c;
}
.event_Paragraph {
  font-family: Helvetica;
  width: 45%;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
}

.event_page_button {
  position: absolute;
  top: 88%;
  /* display: flex;
    left: 24%; */
  padding: 13px 0px 0px 0px;
  gap: 13px;
}
.event_page_button button {
  background-color: #000000cc;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: Helvetica;
  padding: 14px 24px 14px 24px;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  min-height: 17px;
  cursor: pointer;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.resBtn {
  background-color: #ffffff;
  color: black;
}
.resBtn:hover {
  color: white;
  background-color: #e4ac4c;
}
.notorious_event_page_button {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 85%;
  gap: 10px;
  align-items: start;
  margin-left: 57px;
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .event_page_button button {
    padding: 8px 10px 10px 13px;
    font-size: 14px;
  }
  .event_child_container {
    position: relative;
    background-color: #000;
    text-align: center;
    /* height: 183px; */
    height: 230px;
  }
  .aboutLeftText_contact_us {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #ffffff;
    margin-right: 0%;
    text-align: center;
    margin: 0px;
    margin-top: 25px;
  }
  .image_event_main_conatiner {
    display: none;
  }
  .event_Paragraph {
    width: 100%;
    color: white;
    font-size: 14px;
  }
  .notorious_event_page_button {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 89%;
    gap: 10px;
    align-items: center;
    margin: 0px 24px 0px 14px;
  }
}
@media (min-width: 1700px) and (max-width: 3000px) {
  .my_event_picture {
    height: 554px;
  }
  .child_container {
    padding: 55px;
  }
  .event_page_button {
    top: 80%;
    padding: 15px 0px;
  }
}
